@import "src/styles/references/index.scss";

.vt-dashboard-jibimo-settings {
  .card-body {
    display: flex;
    align-items: center;

    .gateway-item {
      .card-body {
        display: unset !important;
        width: 85% !important;

        @include vt-pre-lg {
          width: 100% !important;
        }

        .switches {
          display: flex;
          justify-content: space-between;
          align-items: center;

          @include vt-pre-lg {
          }
        }
        input {
          font-family: monospace !important;
        }
      }
      .vt-input {
        .input-field {
          margin-bottom: 32px;
        }
      }
      .vt-button {
        @include vt-pre-lg {
          margin-top: 16px;
        }
      }
    }
  }
}
