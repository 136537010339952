@import "src/styles/references/index.scss";

.vt-dashboard-users-create {
  padding: 30px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  column-gap: 50px;
  background: $white;
  border-radius: 20px;
  h3 {
    margin-bottom: 24px;
  }

  form {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 8px;

    > .row {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 24px;

      .ant-select {
        width: 50%;

        &:last-child {
          margin-right: 24px;
        }

        .ant-select-selector {
          height: 48px;
          padding: 0 10px;
          border-radius: 10px;
          border: 1px solid $table-border;

          ::placeholder {
            color: $table-border;
          }
        }
      }

      .vt-dropdown {
        max-width: unset;
        width: 50%;

        .dropdown-selector {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 16px;
        }

        .dropdown-items {
          max-width: unset;
          width: 100%;
        }
      }

      &:last-child {
        justify-content: flex-end;

        .vt-button {
          margin: 0;
          margin-right: 24px;

          button {
            height: 48px !important;
          }
        }
      }

      .vt-input {
        width: 50%;
      }

      .vt-input:last-child {
        margin-right: 24px;
      }

      .vt-button {
        margin-top: 24px;
        width: 50%;
      }
    }

    .users-transfer {
      direction: ltr;
      margin-top: 24px;
      width: 100%;

      .ant-transfer-list-header-selected {
        direction: rtl;
      }

      .ant-transfer-list-content {
        direction: rtl;

        .ant-transfer-list-content-item-text {
          margin-right: 4px;
        }
      }

      .ant-transfer-list {
        direction: ltr;
        width: 50%;
        border-radius: 10px;
      }
    }

    > .vt-input {
      width: 100%;
      margin-top: 24px;
    }
  }
}
