@import "src/styles/references/index.scss";

.vt-dashboard-users {
  padding: 30px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  column-gap: 50px;
  background: $white;
  border-radius: 20px;
  margin-bottom: 100px;

    .ant-picker {
      min-width: max-content !important;
      border: 1px solid #3f62fe;
      height: 38px;

      input {
        &::placeholder {
          color: #3f62fe;
        }
      }

      svg {
        fill: #3f62fe;
      }
    }

  .ant-select {
    .ant-select-selector {
      min-height: 38px !important;
      min-width: 140px;
      background: #ffffff;
      border: 1px solid #3f62fe !important;
      color: $info;
      transition: 0.4s;

      .ant-select-selection-placeholder {
        color: $info;
      }
    }

    .ant-select-arrow {
      svg {
        path {
          fill: $info;
        }
      }
    }
  }

  [data-column-id="registeredOn"] {
    .column-date {
      span {
        display: inline-block;
        width: 100%;
      }
      span:last-child {
        font-size: 10px;
        color: $medium-gray;
      }
    }
  }

  > .card-body {
    margin: 0;
    width: 100%;
  }

  .users-table {
    margin: 0;
  }
}

.financial-settings-modal {
  .vt-input,
  .ant-switch {
    margin-top: 16px;
  }

  .content > .vt-button {
    margin-top: 16px;
  }
}
