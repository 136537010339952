@import "src/styles/references/index.scss";

.vt-dashboard-transfer-tron-settings {
  .card-body {
    display: flex;
    align-items: center;
    flex-direction: column;

    .automation-item {
        padding: 12px;
        .card-body {
          row-gap: 24px;
          flex-direction: inherit;
          width: 85% !important;
  
          @include vt-pre-lg {
            width: 100% !important;
          }
  
          .switches {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
  
          .ant-switch {
  
            display: flex;
            align-items: center;
            width: max-content;
            .ant-switch-inner {
              font-size: 14px;
            }
          }
  
        }
      }

    .delay-item {
      .card-body {
        display: unset !important;
        width: 35% !important;

        @include vt-pre-lg {
          width: 100% !important;
        }

        .switches {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        font-family: monospace !important;
      }
    }
    .vt-input {
      .input-field {
        margin-bottom: 32px;
      }
    }
  }
}
