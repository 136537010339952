@import "src/styles/references/index.scss";

.vt-dashboard-referral-withdrawal {
  [data-column-id="createdOn"] {
    .column-date {
      span {
        display: inline-block;
        width: 100%;
      }
      span:last-child {
        font-size: 10px;
        color: $medium-gray;
      }
    }
  }
  height: max-content;
}
