@import "styles/references/index.scss";
.vt-pagination {
  width: 100%;
  margin-top: 32px;
  text-align: center;

  .pagination-row {
    display: flex;
    justify-content: center;
    align-items: center;

    > .anticon {
      cursor: pointer;
      margin-right: 10px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      border: 1px solid $table-border;
      height: 40px;
      width: 40px;
      color: $info;
      transition: 0.4s;

      @include vt-pre-lg {
        margin-right: 3px;
      }

      &.anticon-double-right,
      &.anticon-double-left {
        @include vt-pre-lg {
          display: none;
        }
      }

      &.disabled {
        opacity: 0.5;
      }

      &:hover {
        background: linear-gradient(95.43deg, #3f62fe -25.7%, #678af5 112.1%);
        color: $white;
        transition: 0.4s;
      }
    }

    .vt-button {
      margin-left: 0;
      margin-right: 10px;
      max-width: max-content;

      button {
        height: 40px;
        width: 60px;
        padding: 10px;
        border-radius: 6px;

        border: 1px solid $table-border !important;

        &.info {
          background: linear-gradient(95.43deg, #3f62fe -25.7%, #678af5 112.1%);
        }
      }
    }

    .pages {
      display: flex;
      justify-content: center;
      align-items: center;

      .vt-button {
        margin-right: 10px;
        @include vt-pre-lg {
          margin-right: 3px;
        }
        button {
          height: 40px;
          width: 40px;
          padding: 10px;
          border-radius: 6px;
          border: 1px solid $table-border !important;

          &.info {
            background: linear-gradient(
              95.43deg,
              #3f62fe -25.7%,
              #678af5 112.1%
            );
          }
        }
      }
    }
  }

  .foot {
    margin: 16px auto;
    font-size: 16px;
    b {
      font-weight: 500;
      margin: 0px 4px;
    }
  }
}
