@import "src/styles/references/index.scss";

.vt-dashboard-levels-edit-referral {
  padding: 30px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  column-gap: 50px;
  background: $white;
  border-radius: 20px;
  h3 {
    margin-bottom: 24px;
  }

  form {
    flex-wrap: wrap;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: unset;
    padding: 8px;

    textarea.ant-input {
      margin-top: 24px;
      width: 100%;
      min-height: 250px;
      max-height: 250px;
    }

    .ant-switch {
      display: flex;
      align-items: center;
      width: max-content;
      .ant-switch-inner {
        font-size: 14px;
      }
    }

    .vt-switch-row {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 8px;
      margin-top:24px;
      label {
        font-size: 12px;
      }
      .ant-switch {
        width: max-content;
        margin-left: 0px;
      }
    }

    .switches {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-top: 24px;
    }

    > .row {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .vt-input {
        flex: 50% !important;
      }

      .vt-input:last-child {
        margin-right: 24px;
      }

      .vt-button {
        margin-top: 24px;
        width: 50%;
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @include vt-pre-lg {
        justify-content: space-between;
      }

      .vt-button {
        margin: 0;
        margin-top: 24px;

        &:last-child {
          margin-right: 24px;
        }

        button {
          height: 48px !important;
        }
      }
    }

    .levels-transfer {
      direction: ltr;
      margin-top: 24px;
      width: 100%;

      .ant-transfer-list-header-selected {
        direction: rtl;
      }

      .ant-transfer-list-content {
        direction: rtl;

        .ant-transfer-list-content-item-text {
          margin-right: 4px;
        }
      }

      .ant-transfer-list {
        direction: ltr;
        width: 50%;
        border-radius: 10px;
      }
    }

    > .vt-input {
      width: 100%;
      margin-top: 24px;
      flex: 50%;
      padding: 2px;
    }
  }
}
