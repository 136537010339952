@import "src/styles/references/index.scss";

.vt-dashboard-pm-settings {
  .card-body {
    margin: 32px auto 0;
    width: 35% !important;

    @include vt-pre-lg {
      width: 100% !important;
    }
  }
  .vt-input {
    .input-field {
      margin-bottom: 32px;
    }
  }
}
