@import "src/styles/references/index.scss";

.vt-dashboard-bank-accounts-edit {
  padding: 30px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  column-gap: 50px;
  background: $white;
  border-radius: 20px;

  .card-body {
    width: 100% !important;
    width: 100%;
    row-gap: 24px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 8px;

    > .row {
      width: 35%;
      display: flex;
      justify-content: center;
      align-items: center;

      @include vt-pre-lg {
        width: 100%;
      }

      .vt-input {
        width: 50%;
      }

      .vt-button {
        width: 50%;
        margin: unset !important;

        &:last-child {
          margin-right: 16px !important;
        }

        button {
          height: 52px !important;
        }
      }
    }

    .bank-accounts-transfer {
      direction: ltr;
      margin-top: 24px;
      width: 100%;

      .ant-transfer-list-header-selected {
        direction: rtl;
      }

      .ant-transfer-list-content {
        direction: rtl;

        .ant-transfer-list-content-item-text {
          margin-right: 4px;
        }
      }

      .ant-transfer-list {
        direction: ltr;
        width: 50%;
        border-radius: 10px;
      }
    }

    > .vt-input {
      width: 35%;
      margin-top: 24px;

      @include vt-pre-lg {
        width: 100%;
      }

      input {
        font-family: monospace !important;
      }
    }
  }
}
