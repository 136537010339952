@import "styles/references/index.scss";

.vt-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 340px;
  width: 400px;
  .head {
    display: flex;
    justify-content: space-between;


    svg {
      cursor: pointer;
    }
  }

  .textarea {
    position: relative;
    width: 100%;

    textarea {
      width: 100%;
      height: 100px;
      resize: none;
      outline: none;
    }

    label {
      font-size: 12px;
      position: absolute;
      top: -18px;
    }
  }

  .modal-box {
    background: $white;
    border-radius: 10px;

    z-index: 10;
    padding: 32px 16px;

    .content {
      display: flex;
      flex-direction: column;
      row-gap: 2px;
    }
    .head {
      width: 100%;
      margin-bottom: 38px;

      flex-direction: inherit;
      align-items: center;

      b {
        text-align: center;
        font-size: 18px;
        font-weight: 500;
      }
    }
    .ant-row {
      flex-direction: column !important;
      row-gap: 6px;
    }
    .text {
      width: 100%;
      margin: 10px auto;

      p {
        text-align: center;
        color: $gray-span;
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      .vt-button:first-child {
        margin-right: 0px;
      }
      .vt-button {
        max-width: 130px;

        button {
          height: 40px;
          margin: 0 5px;

          &.outlined {
            border: 1px solid $gray-span !important;
            color: $gray-span;

            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}
