@import "src/styles/references/index.scss";

.vt-dashboard-mannual-authmedia-detail {
  margin-bottom: 150px;

  .ant-image {
    &:not(:first-child) {
      margin-right: 16px;
    }
  }

  .ant-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ant-col {
      min-width: 100px;

      > span:first-child {
        color: $gray-span;
        margin-left: 8px;
      }
    }
  }
}
