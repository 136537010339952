@import "styles/references/index";

.vt-signin {
  height: 100vh;
  display: flex;
  padding: 30px;
  justify-content: center;
  align-items: center;
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;

  @include vt-pre-lg {
    flex-direction: column;
    padding: 0 30px 0px;
  }

  .signin-right {
    position: relative;
    height: 100%;
    max-width: 651px;
    max-height: 720px;
    align-self: center;
    width: 35%;
    z-index: 0;
    @include vt-pre-lg {
      width: 100%;
      height: 50%;
    }
    text-align: center;
    color: $white;
    h2 {
      color: $white;
    }
    .vt-signin-slider {
      width: 100%;
      height: 100%;

      @include vt-pre-lg {
        position: absolute;
        top: 0px;
        left: 0;
        width: 100%;
        height: 200%;
      }

      @include vt-pre-md {
        height: 100%;
      }
      .swiper {
        height: 100%;
        @include vt-pre-lg {
          height: 50%;
          margin-bottom: 24px;
        }
        @include vt-pre-md {
          height: 100% !important;
          margin-bottom: 0;
        }
      }
      .swiper-slide {
        border-radius: 20px;

        @include vt-pre-lg {
          border-top-right-radius: unset;
          border-top-left-radius: unset;
        }
      }
      .slide-content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 100%;
        padding: 64px 20px;

        h2 {
          @include vt-size(font-size, 28px, 14px);
        }

        p {
          @include vt-size(font-size, 16px, 10px);
        }

        @include vt-pre-lg {
          padding: 34px 20px;
        }

        .footer {
          margin-top: auto;
        }

        img {
          width: 85%;
          @include vt-pre-lg {
            width: 45%;
          }

          @include vt-pre-md {
            width: 90%;
          }

          @include vt-pre-sm {
            width: 80%;
          }

          @include vt-pre-xs {
            width: 60%;
          }
        }
      }
    }
  }

  .signin-left {
    position: relative;
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;

    @include vt-pre-lg {
      width: 100%;
      height: 100%;
    }

    @include vt-pre-md {
      width: 100%;
    }

    .signin-stage {
      @include vt-pre-md {
        width: 100%;
      }
    }

    .back-btn {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      left: 0;
      top: 25px;
      cursor: pointer;

      @include vt-pre-lg {
        display: none;
      }
    }

    .back-btn.disabled {
      opacity: 0.5;
    }

    h3 {
      font-size: 24px;
      padding: 10px 0px 20px;
    }
  }
}
