@import "styles/references/index.scss";

.currency-transactions {
  width: 100%;

  .desktop-show {
    @include vt-pre-lg {

    }

    > .card-body {
      margin: 0;
      width: 100%;
    }

    .fields {
      column-gap: 16px;
    }
    .vt-data-table {
      width: 100%;
      padding: 0;
      color: $bold-span;
      border-radius: 10px;
      width: auto !important;
      margin-top: 24px;
      margin-bottom: 24px;

      .rdt_Table {
        border: 1px solid $table-border;

        .rdt_TableHeadRow {
          background-color: #d9dfff33;
          color: #9697a0;
        }
      }

      [data-column-id="createdAt"] {
        .column-date {
          span {
            display: inline-block;
            width: 100%;
          }
          span:last-child {
            font-size: 10px;
            color: $medium-gray;
          }
        }
      }


      [data-column-id="bankAccount"] {
        .column-bankAccount {
          font-size: 14px;
          span {
            display: inline-block;
            width: 100%;
          }
          p:last-child {
            font-size: 10px;
            color: $medium-gray;
            margin: 0;
          }
        }
      }

      [data-column-id="currencyName"] {
        img {
          width: 26px;
          height: 26px;
          margin-left: 16px;
        }
      }

      [data-column-id="date"] {
        .column-date {
          span {
            display: inline-block;
            width: 100%;
          }
          span:last-child {
            font-size: 10px;
            color: #a8a8a8;
          }
        }
      }

      [data-column-id="state"] {
        .vt-badge {
          font-size: 12px;
          min-width: max-content;
          display: flex;
          justify-content: center;

          > div {
            height: 30px;
            display: flex;
            justify-content: flex-end;
            column-gap: 5px !important;
            text-align: right;
            &.success {
              background-color: $success-badge;
              img {
                order: 2;
                margin-left: unset !important;
              }
              span {
                color: $success;
                order: 1;
              }
            }
            &.error {
              background-color: $danger-badge;
              img {
                order: 2;
              }
              span {
                color: $danger;
                order: 1;
              }
            }
            &.waiting {
              background-color: $waiting-badge;
              img {
                order: 2;
              }
              span {
                color: $warning !important;
                order: 1;
              }
            }
          }
        }
      }
      [data-column-id="trackingCode"] {
        span {
          font-family: monospace !important;
        }
      }
      [data-column-id="detail"] {
        a {
          margin-right: 8px;
        }
        span {

          cursor: pointer;
          svg {
            path {
              fill: $info;
            }
          }
        }
      }
    }
  }

  .mobile-show {
    display: none;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;

    @include vt-pre-lg {
      display: flex;
      padding: 0;
      padding-bottom: 50px;
    }

    .filter-row .fields {
      > div {
        margin-bottom: 16px;

        &:first-child,
        &:nth-child(2),
        :nth-child(3) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }

        .vt-dropdown,
        &.vt-dropdown {
          min-width: unset !important;
          max-width: unset !important;
          border-radius: 10px;
          padding: 0 16px;

          > .dropdown-selector {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            svg {
              margin-left: 8px;
              width: 15px;
              height: 15px;
            }
          }

          > .dropdown-items {
            width: 100%;
            max-width: unset;
            margin: 0;

            .dropdown-item {
              img {
                margin-left: 8px;
                width: 15px;
                height: 15px;
              }
              span {
                font-size: 10px;
              }
            }
          }
        }

        &:first-child {
          .vt-dropdown {
            width: 50%;

            &:last-child {
              margin-right: 16px;
            }
          }
        }
        &:nth-child(2) {
          .vt-calendar {
            &:last-child {
              margin-right: 16px;
            }
          }
        }
      }
    }

    > li {
      background-color: $white;
      width: 100%;
      padding: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      border: 1px solid $table-border;
      border-left: 0;
      border-right: 0;
      max-width: 425px;

      &:not(li:last-child) {
        border-bottom: 0px;
      }
      padding-bottom: 32px;

      > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        .right,
        .left {
          display: flex;
          width: max-content;
        }
      }

      > div:first-child {
        .right {
          justify-content: flex-start;
          align-items: center;

          img,
          p,
          span {
            margin-left: 8px;
          }

          img {
            width: 25px;
            height: 25px;
          }

          p {
            font-size: 12px;
            font-weight: 500;
          }

          span {
            color: $gray-text;
            font-family: "Montserrat";
            font-size: 12px;
          }
        }

        .left {
          justify-content: flex-start;
          align-items: center;

          p {
            margin-right: 3px;
          }
        }
      }

      > div:nth-child(2) {
        .left,
        .right {
          p:last-child {
            margin-right: 3px;
          }
          span {
            font-weight: 500;
            margin-right: 3px;
          }
        }
      }

      > div:nth-child(3) {
        .right {
          span {
            margin-right: 3px;
            font-size: 12px;
            color: $gray-text;
          }
        }
      }

      .vt-button {
        max-width: unset;
        margin: 0;

        button {
          border-color: $table-border !important;
          height: 38px;
        }
      }
    }
  }

  .filter-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;

    @include vt-pre-lg {
      flex-direction: column;
    }

    > .ant-divider {
      @include vt-pre-lg {
        display: none;
      }
    }

    .buttons,
    .fields {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .buttons {
      width: 100%;
      justify-content: flex-end;

      @include vt-pre-lg {
        justify-content: space-between;
        margin-bottom: 16px;
      }

      > span {
        cursor: pointer;
        width: max-content;
        color: $danger;
        min-width: 60px;
      }

      > .vt-button {
        min-width: unset;
        width: 100%;
        margin: 0 0 0 16px;

        @include vt-pre-lg {
          margin: 0 auto;
        }

        &:last-child {
          margin-right: 16px;

          button {
            background-color: $danger-badge;
            color: $danger;
            border: 1px solid $danger;
          }
        }
        button {
          font-weight: 500;
        }
      }
    }
    .fields {
      width: 100%;
      @include vt-pre-lg {
        flex-direction: column;
      }

      > div {
        min-width: 130px;
        width: 100%;
        color: $info;
        border-radius: 10px;
        margin-left: 16px;

        @include vt-pre-lg {
          margin-left: 0;
        }

        &:last-child:not(.date) {
          margin-right: 16px;

          img {
            width: 25px;
            height: 25px;
            margin-left: 8px;
          }
        }
      }

      &:first-child {
        @include vt-pre-lg {
          margin-top: 16px;
        }
      }

      .vt-dropdown {
        img {
          width: 25px;
          height: 25px;
          margin-left: 8px;
        }
        span {
          font-size: 10px;
        }
        &:first-child {
          ul {
            height: max-content;
          }
        }
      }

      .vt-calendar {
        cursor: pointer;
        .vt-input {
          .input-field {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 16px;
            background-color: $white;

            input {
              cursor: pointer;
            }

            svg {
              path {
                fill: $info;
              }
            }
          }
        }
      }
    }
  }

  .summary {
    display: flex;
    justify-content: space-around;
  }
}

.ant-dropdown {
  max-width: max-content !important;
  width: 124px !important;
  text-align: right;

  .ant-dropdown-menu-title-content {
    text-align: right;
  }
}

.ant-dropdown-button {
  width: max-content !important;
  height: 48px !important;
  min-width: 130px !important;
  .ant-dropdown-trigger {
    border-right: 0px !important;
  }

  button:focus {
    outline: unset !important;
    border-color: unset !important;
    z-index: unset !important;
    color: #d5d5d5 !important;
  }

  button:first-child {
    width: 130px !important;
    border-left: 0px;
    height: 48px;
    border-bottom-right-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
  button:nth-child(2) {
    height: 48px;
    border-bottom-left-radius: 8px !important;
    border-top-left-radius: 8px !important;
  }


}

