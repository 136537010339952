@import "src/styles/references/index.scss";

.vt-dashboard-withdrawal-commission-settings {
  .card-body {
    display: flex;
    align-items: center;

    .delay-item {
      .card-body {
        display: unset !important;
        width: 35% !important;

        @include vt-pre-lg {
          width: 100% !important;
        }

        .switches {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        font-family: monospace !important;
      }
    }
    .vt-input {
      .input-field {
        margin-bottom: 32px;
      }
    }
  }
}
