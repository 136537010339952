@import "src/styles/references/index.scss";

.vt-dashboard-mindeposit-settings {
  > .card-body {
    width: 100% !important;

    @include vt-pre-lg {
      width: 100% !important;
    }

    > .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h5 {
        width: max-content;
        width: 20%;
      }

      > .switch,
      > .vt-input,
      > .ant-select {
        margin-top: 24px;
        width: 35%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:last-child {
          margin-right: 24px;
        }
      }
      &.col3 {
        align-items: start;

        .gateway-item {
          .switch,
          .vt-input {
            margin-top: 24px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:last-child {
              margin-right: 24px;
            }
          }
        }
      }

      > .ant-select {
        .ant-select-selector {
          height: 48px;
          border-radius: 10px;
        }
      }

      > .vt-button {
        margin: 0;
        margin-right: 24px;
        margin-top: 24px;

        button {
          height: 48px !important;
        }
      }

      &.buttons {
        justify-content: flex-end;

        .vt-button {
          margin: 0;
          margin-right: 24px;
          margin-top: 24px;

          button {
            height: 48px !important;
          }
        }
      }

      .vt-input {
        width: 35%;
      }

      .ant-select {
        width: 50%;
      }

      .vt-input:last-child {
        margin-right: 24px;
      }

      .vt-button {
        margin-top: 24px;
        width: 50%;
      }
    }
  }
  .vt-input {
    width: 35% !important;
    margin: auto;
    .input-field {
      margin-bottom: 32px;
    }
  }
}
