@import "src/styles/references/index.scss";

.vt-dashboard-blacklists-create {
  padding: 30px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  column-gap: 50px;
  background: $white;
  border-radius: 20px;
  h3 {
    margin-bottom: 24px;
  }

  form {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 8px;

    .ant-row {
      width: 100%;
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-items: flex-start;

      .vt-button {
        margin: 0;
        button {
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    .vt-input {
      width: 50%;
    }

    textarea.ant-input {
      margin-top: 24px;
      width: 100%;
      min-height: 250px;
      max-height: 250px;
    }

    > .row {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .vt-input {
        width: 50%;
      }

      .vt-input:last-child {
        margin-right: 24px;
      }

      .vt-button {
        margin-top: 24px;
        width: 50%;
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @include vt-pre-lg {
        justify-content: space-between;
      }

      .vt-button {
        margin: 0;
        margin-top: 24px;

        &:last-child {
          margin-right: 24px;
        }

        button {
          height: 48px !important;
        }
      }
    }

    > .vt-input {
      width: 100%;
      margin-top: 24px;
    }
  }
}
