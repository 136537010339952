@import "src/styles/references/index";
.bottom-menu-items {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: $gray-span;

  a.bottom-menu-item {
    color: $gray-span;
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 10px;

    .bottom-menu-icon {
      svg {
        vertical-align: middle;
      }
    }

    &.active {
      .bottom-menu-icon {
        background-color: $info;
        padding: 20px;
        border-radius: 20px;
        margin-top: -40px;

        svg {
          path {
            fill: $white;
          }
        }
      }
    }
  }
  a.bottom-menu-item:nth-child(4) {
    &.active {
      svg {
        path {
          fill: $gray-span;
        }
        rect {
          fill: $white;
        }
      }
    }
  }
}
