@import "src/styles/references/index.scss";

.vt-dashboard-bonuses {
  padding: 30px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  column-gap: 50px;
  background: $white;
  border-radius: 20px;
  h3 {
    margin-bottom: 24px;
  }

  > .card-body {
    margin: 0;
    width: 100%;
  }

  .bonus-datatable {
    margin: 0;

    .rdt_TableCell[data-column-id="symbol"] div {
      font-family: monospace !important;
    }

    .column-date {
      span {
        display: inline-block;
        width: 100%;
      }
      span:last-child {
        font-size: 10px;
        color: $medium-gray;
      }
    }

    header {
      flex-direction: row;
    }
  }

  .row {
    display: flex;
    justify-content: flex-end;
    margin: 0 24px;

    .vt-button {
      margin: 0 24px;
      margin-left: 0 !important;
    }
  }

  .black-list-datatable {
    .actions {
      a {
        margin-left: 8px;
      }
    }
  }
}
