@import "src/styles/references/index.scss";

.vt-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background: $bg;
  left: 0;
  top: 0;
  width: 100%;
  padding: 20px 22% 5px 7%;
  z-index: 99;

  @include vt-pre-lg {
    display: none;
  }

  &.active {
    @include vt-pre-lg {
      display: block;
    }
  }

  @include vt-pre-lg {
    margin: 0 !important;
    padding: 12px 0;
    left: 5%;
    top: 0;
    width: 90%;
    margin: 5px auto;
  }

  .navbar-welcome {
    @include vt-pre-lg {
      display: none;
    }
  }

  .navbar-date {
    direction: ltr;

    display: flex;

    gap: 10px;

    @include vt-pre-lg {
      display: none;
    }

    span {
      color: $gray-span;
    }
  }

  .navbar-user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    padding-left: 20px;


    @include vt-pre-lg {
      padding-left: 0;
      width: 100%;
      justify-content: space-between;
    }

    .mobile-leftside {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;

      @include vt-pre-lg {
        order: 2;
      }

      > svg {
        display: none;
        @include vt-pre-lg {
          display: block;
        }
      }

      .navbar-search {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-self: flex;
        display: none;
        background-color: $white;
        width: 30px;
        height: 30px;
        border-radius: 10px;
        order: 2;

        @include vt-pre-lg {
          display: flex;
        }

        img {
          vertical-align: middle;
          margin: auto;
          width: 30px;
          height: 30px;

          @include vt-pre-lg {
            width: 15px;
            height: 15px;
          }
        }
      }

      .navbar-notification {
        position: relative;
        border-radius: 10px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $white;
        border-radius: 10px;
        cursor: pointer;

        @include vt-pre-lg {
          width: 30px;
          height: 30px;
          order: 1;
        }

        img {
          vertical-align: middle;
          margin: auto;
          width: 19px;
          height: 19px;

          @include vt-pre-lg {
            width: 15px;
            height: 15px;
          }
        }
      }

      .navbar-notification::after {
        position: absolute;
        display: block;
        top: 2px;
        right: 5px;
        width: 15px;
        height: 15px;
        text-align: center;
        vertical-align: middle;
        content: "3";
        background-color: $danger;
        color: $white;
        border-radius: 50%;
        font-size: 10px;

        @include vt-pre-lg {
          width: 10px;
          font-size: 8px;
          height: 10px;
        }
      }
    }

    .navbar-profile {
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 10px;

      @include vt-pre-lg {
        order: 1;
      }
    }

    .navbar-profile > .dropdown {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 10px;
      @include vt-pre-lg {
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
      }

      > span {
        font-size: 14px;
        color: $gray-span;
        @include vt-lg {
          display: none;
        }
      }
      p.userinfo {
        margin: 0;
      }
      svg {
        @include vt-pre-lg {
          display: none;
        }
      }

      .dropdown-body {
        position: absolute;
        top: 48px;
        left: -5px;
        background-color: $white;
        border-radius: 10px;
        width: 150px;
        height: max-content;
        padding: 5px;
        cursor: pointer;
        transform: 0.5s;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        filter: drop-shadow(0px 100.058px 80.0461px rgba(93, 128, 248, 0.05))
          drop-shadow(0px 64.8521px 46.8788px rgba(93, 128, 248, 0.037963))
          drop-shadow(0px 38.5407px 25.4961px rgba(93, 128, 248, 0.0303704))
          drop-shadow(0px 20.0115px 13.0075px rgba(93, 128, 248, 0.025))
          drop-shadow(0px 8.15284px 6.52227px rgba(93, 128, 248, 0.0196296))
          drop-shadow(0px 1.85292px 3.14996px rgba(93, 128, 248, 0.012037));

        @include vt-pre-lg {
          display: none;
        }

        > svg {
          position: absolute;
          top: -7px;
          right: 5px;
        }

        span.divider {
          position: relative;
          left: 5px;
          display: inline-block;
          width: calc(100% + 10px);
          height: 1px;
          background-color: $table-border;
          margin: 1px 0;
        }

        .item:hover {
          background-color: #f1f8ff;
          transition: 0.5s;
        }

        a.item {
          color: $bold-span !important;
        }

        .item {
          padding: 5px;
          border-radius: 5px;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin: 3px 0 0 0;

          &:last-child {
            margin-bottom: 3px;
          }

          svg {
            width: 16px;
            height: 16px;
          }

          span:last-child {
            margin-right: 5px;
            display: unset;
          }
        }
      }
    }

    .navbar-profile > .avatar {
      background: linear-gradient(95.43deg, #3f62fe -25.7%, #678af5 112.1%);
      border-radius: 5px;

      > img {
        width: 40px;
        height: 40px;
      }
    }
  }
}
