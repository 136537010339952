@import "src/styles/references/index.scss";

.vt-dashboard-mannual-carts-detail {
  margin-bottom: 150px;
  .card-body {
    display: flex;
    flex-wrap: wrap;

    .ant-image {
      &:not(:first-child) {
        margin-right: 16px;
      }
    }

    .vt-input {
      flex: 1 0 21%; /* explanation below */
      margin: 5px;
      height: 100px;
    }
  }
  .ant-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .texts {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 16px 0;

      .vt-button {
        margin-top: unset;
      }
    }

    > &.buttons {
      justify-content: flex-end;

      .vt-button {
        margin: 0;
        margin-right: 24px;
        margin-top: 24px;

        button {
          height: 48px !important;
        }
      }
    }

    textarea.ant-input {
      margin-top: 24px;
      width: 100%;
      min-height: auto;

    }

    &.files {
      img {
        margin-top: 24px;
        margin-left: 16px;
        width: 420px;
        height: 420px;
      }
    }

    .ant-col {
      min-width: 100px;

      > span {
        color: $gray-span;
      }
    }

    .vt-button {
      margin-top: 24px;
      width: 50%;
    }
  }
}
