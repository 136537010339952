@mixin rtl {
    @at-root body[dir="rtl"] & {
        @content;
    }
}

@mixin vt-xs {
    @media (max-width: 575.98px) {
        @content;
    }
}

@mixin vt-sm {
    @media (min-width: 575.98px) {
        @content;
    }
}

@mixin vt-md {
    @media (min-width: 767.98px) {
        @content;
    }
}

@mixin vt-lg {
    @media (min-width: 991.98px) {
        @content;
    }
}

@mixin vt-xl {
    @media (min-width: 1199.98px) {
        @content;
    }
}
@mixin vt-pre-xs {
    @media (max-width: 575.98px) {
        @content;
    }
}

@mixin vt-pre-sm {
    @media (max-width: 575.98px) {
        @content;
    }
}

@mixin vt-pre-md {
    @media (max-width: 767.98px) {
        @content;
    }
}

@mixin vt-pre-lg {
    @media (max-width: 991.98px) {
        @content;
    }
}

@mixin vt-pre-xl {
    @media (max-width: 1199.98px) {
        @content;
    }
}

@mixin vt-size($property, $web-value, $mobile-value, $important: null) {
    @include vt-xs {
        #{$property}: $mobile-value $important;
    }
    @include vt-sm {
        #{$property}: (2 * $mobile-value + $web-value)/3 $important;
    }
    @include vt-md {
        #{$property}: ($mobile-value + $web-value)/2 $important;
    }
    @include vt-lg {
        #{$property}: ($mobile-value + 2 * $web-value)/3 $important;
    }
    @include vt-xl {
        #{$property}: $web-value $important;
    }
}

@mixin rtl {
    @at-root body[dir="rtl"] & {
        @content;
    }
}

@mixin ltr {
    @at-root body[dir="ltr"] & {
        @content;
    }
}
