@import "src/styles/references/index";

.vt-panel-card {
  width: 100%;
  height: 100%;
  background-color: $white;
  padding: 24px;
  border-radius: 8px;

  &.shadowed {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  display: flex;
  justify-content: start;
  flex-direction: column;
  overflow: hidden;

  .head {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px;
    width: 100%;

    p {
      font-size: 15px;
      line-height: 26px;
      font-weight: 300;
    }

    img {
      height: 34px;
    }

    .vt-button {
      margin: unset !important;
    }

    h3 {
      font-size: 16px;
      color: #000000;
    }
  }

  .card-body {
    width: 95%;
  }
}
