@import "src/styles/references/index.scss";

.vt-dashboard-fiats {
  padding: 30px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  column-gap: 50px;
  background: $white;
  border-radius: 20px;

  h3 {
    margin-bottom: 24px;
  }

  .partners-prices {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;

    .vt-data-table {
      width: 45%;

      &:last-child {
        margin-right: 16px;
      }
    }
  }

  .fiats {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @include vt-pre-lg {
      flex-direction: column;
    }

    form {
      width: 100%;
      display: flex;
      align-self: center;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding: 8px;

      > .vt-button {
        margin: 0;
        align-self: flex-end;
        margin-top: 24px;

        button {
          height: 48px !important;
        }

        @include vt-pre-lg {
          max-width: unset;
        }
      }

      .switches {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      input {
        font-family: monospace !important;
      }

      .vt-input {
        &.has-error {
          .input-field {
            border-color: $danger;
          }
        }

        .input-field {
          margin-bottom: 32px;
        }
      }

      > .row {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 24px;

        > .switch {
          margin-top: 24px;
          width: 50%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:last-child {
            margin-right: 24px;
          }
        }

        &:last-child {
          justify-content: flex-end;

          .vt-button {
            margin: 0;
            margin-right: 24px;

            button {
              height: 48px !important;
            }
          }
        }

        .vt-input {
          width: 50%;
        }

        .vt-input:last-child {
          margin-right: 24px;
        }

        .vt-button {
          margin-top: 24px;
          width: 50%;
        }
      }

      .fiats-transfer {
        direction: ltr;
        margin-top: 24px;
        width: 100%;

        .ant-transfer-list-header-selected {
          direction: rtl;
        }

        .ant-transfer-list-content {
          direction: rtl;

          .ant-transfer-list-content-item-text {
            margin-right: 4px;
          }
        }

        .ant-transfer-list {
          direction: ltr;
          width: 50%;
          border-radius: 10px;
        }
      }

      > .vt-input {
        width: 100%;
        margin-top: 24px;
      }
    }
  }
}
