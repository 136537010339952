@import "src/styles/references/index.scss";

.vt-skeleton-asset {
  &.desktop {
    width: 100%;
    height: 79px;
    column-gap: 5px;
    background: #ffffff;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 8px;

    display: flex;
    align-items: center;
    justify-content: space-around;
    @include vt-pre-lg {
      width: 100%;
      background-color: $bg;
    }
    @include vt-pre-md {
      width: 100%;
      padding: 0;
      margin: 0 auto;
    }

    .box {
      height: 38px;
      width: 38px;
      border-radius: 8px;
    }
    .rectengle {
      height: 24px;
      border-radius: 4px;
      width: 85px;
    }
    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-around;

      .deposit {
        margin-right: 4px;
      }
      .widthrawl,
      .deposit {
        height: 24px;
        border-radius: 4px;
        width: 65px;
      }
    }
  }
  &.mobile {
    column-gap: 5px;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: white !important;
    width: 100%;
    height: 230px;

    @include vt-pre-lg {
      width: 100%;
      background-color: $bg;
    }
    @include vt-pre-md {
      width: 100%;
      padding: 0;
      margin: 8px auto;
    }

    ul {
      padding: 0px;
      width: 100%;
      margin: 0px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .rectengle {
        height: 24px;
        border-radius: 4px;
        width: 85px;
      }

      li {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }
  }
}
