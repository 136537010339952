@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../ttf/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-style: bold;
  font-weight: 700;
  src: url("../ttf/Montserrat-Bold.ttf");
}
