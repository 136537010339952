@import "src/styles/references/index.scss";

.vt-dashboard-dangerous-amount-settings {
  .card-body {
    display: flex;
    align-items: center;

    .delay-item {
      .card-body {
        display: unset !important;
        width: 35% !important;

        @include vt-pre-lg {
          width: 100% !important;
        }

        .switches {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        font-family: monospace !important;
      }
    }
    .vt-input {
      .input-field {
        margin-bottom: 32px;
      }
    }

    .vt-button {
      margin-top: 16px;
    }

    .vt-switch-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      row-gap: 8px;
      label {
        font-size: 12px;
      }
      .ant-switch {
        width: max-content;
        margin-left: 0px;
      }
    }
  }
}
