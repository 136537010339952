@import "src/styles/references/index.scss";

.vt-dashboard-bank-account-tracking {
  padding: 30px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  column-gap: 50px;
  background: $white;
  border-radius: 20px;

  h3 {
    margin-bottom: 24px;
  }

  .en {

  }

  .tracking-info {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .card-info {
      width: 66%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      .ant-row {
        width: 100%;

        display: flex;
        justify-content: space-between;
        align-items: center;
        align-items: flex-start;

        &:not(&:first-child) {
          margin-top: 16px;
        }

        .ant-col {
          min-width: 70px;
          text-align: center;

          &:last-child {
            margin-left: 16px;
          }
        }
      }
    }

    .tracking {
      width: 33%;

      .rangePicker-lbl {
        font-size: 12px;
      }

      .rangePicker {
        width: 100%;
        height: 48px;
        border: 1px solid #d9dfff;
        direction: ltr;
        font-family: monospace !important;

        input, div, p, h, span, td {
          font-family: monospace !important;
        }
      }

      .form {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        padding: 8px;

        input {

        }

        .vt-button {
          width: 100%;
          margin: 0;
          margin-top: 24px;

          button {
            height: 48px !important;
          }

          @include vt-pre-lg {
            max-width: unset;
          }
        }

        > .vt-input {
          width: 100%;
          margin-top: 24px;
        }
      }
    }
  }
}
