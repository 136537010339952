@import "src/styles/references/index.scss";

.vt-dashboard-transactions-edit {
    padding: 30px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    column-gap: 50px;
    background: $white;
    border-radius: 20px;
    h3 {
        margin-bottom: 24px;
    }
    .ant-row {
        width: 100%;
        margin-top:15px ;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .ant-col {
            min-width: 100px;

            > span {
                color: $gray-span;
            }
        }
    }
    form {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        padding: 8px;

        .text-area-field {
            margin-top: 1%;
            width: 100%;
            textarea.ant-input {
                border-radius: 10px;
                margin-top: 4px;
                width: 100%;
                min-height: 250px;
                max-height: 250px;
            }
        }

        > .row {
                margin-top: 3%;

            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &.image {
                justify-content: space-between;
            }

            > .switch {
                margin-top: 24px;
                width: 50%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &:last-child {
                    margin-right: 24px;
                }
            }

            &:last-child {
                justify-content: flex-end;

                .vt-button {
                    margin: 0;
                    margin-right: 24px;
                    margin-top: 24px;

                    button {
                        height: 48px !important;
                    }
                }
            }

            .vt-input {
                width: 50%;
            }

            //.vt-input:last-child,
            //.ant-select:last-child {
            //    margin-right: 24px;
            //}

            .ant-select {
                width: 50%;
                .ant-select-selector {
                    height: 48px;
                    border-radius: 10px;
                }
            }

            .vt-button {
                margin-top: 24px;
                width: 50%;
            }
        }

        .markets-transfer {
            direction: ltr;
            margin-top: 24px;
            width: 100%;

            .ant-transfer-list-header-selected {
                direction: rtl;
            }

            .ant-transfer-list-content {
                direction: rtl;

                .ant-transfer-list-content-item-text {
                    margin-right: 4px;
                }
            }

            .ant-transfer-list {
                direction: ltr;
                width: 50%;
                border-radius: 10px;
            }
        }

        > .vt-input {
            width: 100%;
            margin-top: 24px;
        }
    }
}
