@import "styles/references/index.scss";
@import "styles/animations.scss";

.vt-calendar {
  width: 100%;
  .input-field {
    display: flex;
    align-items: center;
  }
}
.calendar-modal {
  .calendar {
    position: absolute;
    left: calc(50% - 175px);
    top: calc(50% - 160px);
    display: flex;
    width: 350px;
    height: 290px;
    background: $white;
    border-radius: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 10px;
    transition: 0.2s;
    z-index: 100;
    padding-right: 10px;
    animation: vt-fade-in 0.5s 0.2s backwards;
    ::-webkit-scrollbar {
      width: 0px;
    }

    ::-webkit-scrollbar-thumb {
      background: $scroll-thumb-bg;
    }

    ::-webkit-scrollbar-track {
      background: $white;
    }
    .selection {
      margin-top: 24px;
      overflow: auto;
      max-height: 234px;
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 10px 20px;
      justify-content: center !important;
      border-radius: 20px;
      li {
        cursor: pointer;
        border-radius: 10px;
      }
    }

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 10px;
      width: 100%;
      padding: 20px 20px 0px;
      font-size: 16px;
      font-weight: 500;

      & > * {
        cursor: pointer;
      }

      > div:first-child {
      }

      .right-side {
        justify-content: flex-start;
      }

      .left-side {
        justify-content: flex-end;
        flex-wrap: nowrap;
      }

      .right-side,
      .left-side {
        display: flex;
        align-items: center;
        column-gap: 7px;
      }

      .right-side > span:last-child,
      .left-side > span {
        padding: 5px;
        background: $table-border;
        border-radius: 10px;
      }

      .left-side {
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 8px;
          width: 25px;
          height: 25px;
        }
      }
    }

    ul.day-list {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 2px;

      li {
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        min-width: 20px;
        margin: 2px 0;
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }

    ul.month-list > li.active,
    ul.year-list > li.active,
    ul.day-list > li.active {
      color: $white;
      background: $primary;
    }

    ul.month-list {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 10px;

      li {
        text-align: center;
        min-width: 60px;
        padding: 10px 5px;
        margin: 10px 0;
      }
    }

    ul.year-list {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 20px;

      li {
        text-align: center;
        min-width: 50px;
        margin: 5px 0;
        padding: 5px 10px;
      }
    }
  }
}
