.vt-dashboard-add-promocode {
  .card-body {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;

    .vt-input {
      width: 100%;
      margin-top: 32px;
    }

    .rmdp-container {
      width: 100%;
    }

    .ant-select {
      margin-top: 32px;
      height: 48px;

      .ant-select-selector {
        height: 48px;

        .ant-select-selection-placeholder {
          display: flex;
          align-items: center;
          height: 100%;
        }
      }
    }

    label {
      margin-top: 32px;
    }

    .vt-button {
      margin-top: 48px;
    }
  }
}