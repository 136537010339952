@import "styles/references/index";

.signin-stage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 350px;
  row-gap: 30px;

  .input {
    direction: ltr;
    > div {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      > input {
        margin: 0 10px;
        border: 1px solid $table-border;
        border-radius: 10px;
        max-height: 48px;

        @include vt-pre-md {
          margin: 0 5px;
        }
      }
    }
  }

  .signin-input {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    .input-field {
      background-color: $input;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    input {
      background-color: transparent;
      height: 40px;
    }

    .start-adornment {
      margin-right: 10px;
    }
    .end-adornment {
      margin-left: 10px;
    }
  }

  .signin-remember {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 4px;
  }

  .signin-options {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      color: $info;
      cursor: pointer;
    }
  }

  .signin-button {
    max-width: 100%;
    margin: 0;
    button {
      width: 100%;
    }
  }
}
