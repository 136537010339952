.transaction-action-approve-modal {
  .modal-content {
    display: flex;
    flex-direction: column;

    > span {
      align-self: center;
    }

    p {
      margin-inline: auto;
      margin-top: -26px;
    }
  }
}
