.vt-dashboard-edit-promocode {
  .card-body {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;

    .vt-input {
      width: 100%;
      margin-top: 32px;
    }

    .rmdp-container {
      width: 100%;
    }

    label {
      margin-top: 32px;
    }

    .vt-button {
      margin-top: 48px;
    }
  }
}