@import "src/styles/references/index.scss";

.vt-dashboard-gateways {
    width: 100%;
    height: auto;

    .gateways-list {
        width: 100%;
        .gateway-item-card:first-child {
            margin-top: 80px !important;
        }
        .gateway-item-card {
            padding: 30px;
            margin: 24px auto;
            background: $white;

            .ant-row:first-child {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0px 8px;
                column-gap: 10%;
                .gateway-info {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .ant-divider {
                        margin: 32px 0px !important;
                    }
                    .anticon {
                        font-size: 36px;
                        margin-bottom: 16px;
                    }
                    img {
                        height: 58px;
                    }
                }

                .vt-input {
                    width: 320px;
                }
                .vt-switch-row {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    row-gap: 8px;
                    label {
                        font-size: 12px;
                    }
                    .ant-switch {
                        width: max-content;
                        margin-left: 0px;
                    }
                }
                .vt-button {
                    width: max-content;
                    min-width: 130px;

                    button {
                        height: 32px;
                    }
                }
            }
        }
    }
}
