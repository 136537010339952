@import "src/styles/references/index.scss";

.vt-dashboard-tickets {
    padding: 30px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    column-gap: 50px;
    background: $white;
    border-radius: 20px;
    h3 {
        margin-bottom: 24px;
    }

    > .card-body {
        margin: 0;
        width: 100%;
    }

    .ticket-table {
        margin: 0;

        .rdt_TableCell[data-column-id="ticketNumber"] div {
            font-family: monospace !important;
        }

        [data-column-id="createdOn"] {
            .column-date {
                span {
                    display: inline-block;
                    width: 100%;
                }
                span:last-child {
                    font-size: 10px;
                    color: $medium-gray;
                }
            }
        }

        header {
            flex-direction: row;
        }
    }

    .row {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .vt-button {
            margin: 0 24px;
        }
    }
}
