@import "../assets/fonts/Anjoman/css/font.css", "src/styles/references/index",
  "assets/fonts/Montserrat/css/font.css";

html,
body {
  background-color: $bg !important;
  overflow-x: hidden;
}

.vt-dashboard-main::-webkit-scrollbar {
  width: 0px;
} /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $input inset !important;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ant-popover {
  .ant-popover-content {
    margin-right: 32%;
  }

  width: max-content;

  .ant-popover-title {
    min-width: 70px;
  }

  .ant-popover-inner-content {
    width: auto;
    max-width: 1200px;
    height: auto !important;

    p {
      width: max-content;
    }
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translate3d(-100px);
  background-color: rgb(123 123 123 / 81%) !important;
  transition: all 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translate3d(-100px);
}
body {
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  direction: rtl;
  position: relative; //for mobile header handling
}
.rtl {
  direction: rtl !important;
}
.ltr {
  direction: ltr !important;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
.d-flex {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.d-none {
  display: none !important;
}
h1 {
  font-weight: bold !important;
}
body,
a,
button,
span,
li,
ul,
div,
p,
table,
tbody,
thead,
tr,
td,
th,
i,
b,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Anjoman !important;
  font-style: normal !important;
}

.copiable {
  cursor: pointer;
}
span {
  &.en {
    font-family: monospace !important;
  }
  font-family: Anjoman;
  font-style: normal !important;
}
input,
textarea {
  font-family: Anjoman !important;
  font-style: normal !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;

  &.en {
    font-family: monospace !important;
  }
}

.rdt_Table {
  div[role="rowgroup"]:first-child {
    div[role="row"] {
      height: 71px;
      background: rgba(217, 223, 255, 0.2);
      border-radius: 10px 10px 0px 0px;
      border: 1px solid #d9dfff;
      border-bottom: 0px !important;
    }
  }
  div[role="rowgroup"]:nth-child(2) div[role="row"] {
    border: 1px solid #d9dfff !important;
    height: 74px;

    .ant-tag {
      border-radius: 4px;
    }
    &:last-child {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }

  .actions {
    a {
      color: #000000;
      &:hover {
        color: $info;
      }
    }
    span {
      cursor: pointer;
      &:hover {
        color: $info;
      }
      &:not(:first-child) {
        margin-right: 8px;
      }
    }
  }
}

.ant-select {
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  .ant-select-arrow {
    right: unset !important;
    left: 11px !important;
  }
}
.filter-row {
  .search .input-field {
    border: 0 !important;
  }
  .input-field,
  input {
    height: 38px !important;
  }
}

