@use "sass:selector";

$bg: #f1f7ff;
$primary: linear-gradient(95.43deg, #3f62fe -25.7%, #678af5 112.1%);
$secondary: linear-gradient(105.31deg, #21bcf3 -43.21%, #7bd5ed 125.86%);

$input: #f9f9fd;

$success: #2ac769;
$danger: #e93c3c;
$info: #3f62fe;
$warning: #f6a609;

$waiting-badge: rgba(246, 166, 9, 0.2);
$success-badge: #d4f4e1;
$danger-badge: #fedcdc;

$warning-alert: #fef6e6;
$info-alert: #eef2fe;
$gray-text: #a8a9aa;
$gray-span: #859abc;
$bold-span: #383952;
$white-cyan: #c8f1ff;
$transparent-blue: #6581fe;

$scroll-thumb-bg: #c9cfd8;
$primary-alert: #eef2fe;
$alert-message: #383952;
$white: #ffffff;
$table-border: #d9dfff;

$table-head-text: #9697a0;
$table-head-bg: #d9dfff33;
$night-switch-bg: #5d7294;
$c-white: #cccccc;

$medium-gray: #a8a8a8;
$medium-white: #e9e9e9;
$access: #678af5;

$asset-bg: #eff2ff;
