@import "src/styles/references/index.scss";

.vt-dashboard-mannual-validations-detail {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  column-gap: 50px;
  background: $white;
  border-radius: 20px;
  overflow-y: scroll;
  background: $primary !important;
  padding: 16px 32px !important;
  span,
  p {
    color: $white !important;
  }
  .ant-col p {
    margin-bottom: 0 !important;
  }
  .ant-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: flex-start;

    .column-date {
      span {
        text-align: left;
        display: inline-block;
        width: 100%;
      }
      span:last-child {
        text-align: left;

        color: $medium-gray;
      }
    }
    > &.buttons {
      justify-content: flex-end;

      .vt-button {
        margin: 0;
        margin-right: 24px;
        margin-top: 24px;

        button {
          height: 48px !important;
        }
      }
    }

    textarea.ant-input {
      margin-top: 24px;
      width: 100%;
      min-height: auto;

    }

    &.files {
      img {
        margin-top: 24px;
        margin-left: 16px;
        width: 420px;
        height: 420px;
      }
    }

    .ant-col {
      min-width: 100px;

      > span {
        color: $gray-span;
      }
    }

    .vt-button {
      margin-top: 24px;
      width: 50%;
    }
  }
}
