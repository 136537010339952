.market-action-approve-modal {
  .modal-content {
    display: flex;
    flex-direction: column;

    > span {
      align-self: center;
    }
  }
}
