@import "src/styles/references/index";

.vt-button {
  max-width: 180px;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;

  button {
    border: 0;
    outline: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    height: 48px;

    span {
      margin: 0 5px;
    }

    &:hover {
      cursor: pointer;
    }

    &.disable {
      opacity: 0.4;
    }

    &.success {
      background: $success;
      color: $white;
    }

    &.info {
      background: $waiting-badge;
      color: $warning;
    }

    &.trade {
      background: #dcddff;
      color: #605ae5;
    }

    &.primary {
      background: $primary;
      color: $white;
    }

    &.secondary {
      background: $secondary;
      color: $white;
    }

    &.error {
      background: $danger;
      color: $white;
    }

    &.info {
      background: $primary;
      color: $white;
    }

    &.outlined {
      background: $white;
      border: 1px solid $info !important;
      color: $info;
      height: 38px;
      transition: 0.4s;

      &:hover {
        background: $primary;
        color: $white !important;
        transition: 0.4s;
      }
    }
  }
}
