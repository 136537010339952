@import "src/styles/references/index";

.vt-badge {
  max-width: 180px;
  width: max-content;

  div {
    border: 0;
    outline: 0;
    width: 100%;
    display: flex;
    column-gap: 12px;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    width: 100%;
    height: max-content;
    padding: 2px 16px;
    direction: ltr;
    &:hover {
      cursor: pointer;
    }
    &.success {
      background: $success-badge;
      color: #005f27;
    }
    &.primary {
      background: $primary;
      color: $white;
    }
    &.secondary {
      background: $secondary;
      color: $white;
    }
    &.error {
      background: $danger-badge;
      color: $danger;
    }
    &.waiting {
      background: $waiting-badge;
      color: $white;

      span {
        color: rgb(0 0 0 / 40%);
      }
    }
    &.info {
      background: $info;
      color: $white;
    }

    img {
      margin-left: 5px;
    }
  }
}
