@import "styles/references/index.scss";

.currency-transactions-detail-modal {
  .modal-box {
    overflow-y: auto !important;
    position: absolute;
    top: calc(50% - 376px);
    left: calc(50% - 220px);
    width: 420px;
    height: max-content;

    @include vt-pre-lg {
      top: 20px;
    }

    .card-body {
      width: 100%;
      margin: 0;
    }

    .head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h4 {
        font-size: 22px;
      }

      > svg {
        cursor: pointer;
      }
    }

    .content {
      row-gap: 0px !important;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      > .row {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;

        > span:first-child {
          color: $gray-span;
          font-size: 16px;
        }

        > div:last-child {
          > span.en {
            max-width: 250px;
            overflow-x: hidden;
            text-overflow: ellipsis;
          }
        }

        .bankLogo {
          width: 25px;
          height: 25px;
        }

        .coin-info {
          span {
            font-family: Anjoman !important;
          }
        }

        .anticon {
          svg {
            path {
              fill: $primary;
            }
          }
        }
      }

      > .row:nth-child(2)::before,
      > .row:nth-child(3)::before {
        content: "";
        display: inline-block;
        width: 100%;
        height: 0.5px;
        background: $medium-white;
        margin: 8px 0;
      }

      > .row:nth-child(3)::before,
      > .row:nth-child(2)::before,
      > .row:nth-child(4)::before,
      > .row:nth-child(5)::before,
      > .row:nth-child(6)::before,
      > .row:nth-child(7)::before,
      > .row:nth-child(8)::before,
      > .row:nth-child(9)::before,
      > .row:nth-child(10)::before,
      > .row:nth-child(11)::before {
        content: "";
        display: inline-block;
        width: 100%;
        height: 0.5px;
        background: $medium-white;
        margin: 8px 0;
      }

      > .row:nth-child(1),
      > .row:nth-child(2) {
        flex-wrap: wrap;

        .col {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;

          img {
            margin-left: 8px;
            width: 20px;
            height: 20px;
          }

          > span:first-child {
            color: $gray-span;
            font-size: 16px;
          }
        }
      }

      > .row.state > span.success {
        color: $success;
      }
      > .row.state > span.error {
        color: $danger;
      }
      > .row.state > span.waiting {
        color: $warning;
      }

      > .row:nth-child(1) > .col:last-child > div:last-child > span,
      > .row:nth-child(3) > div:last-child > span:first-child,
      > .row:nth-child(4) > div:last-child > span:first-child,
      > .row:nth-child(5) > div:last-child > span:first-child,
      > .row:nth-child(6) > div:last-child > span:first-child {
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 12px;
      }

      > .row:nth-child(3),
      > .row:nth-child(4),
      > .row:nth-child(5),
      > .row:nth-child(6),
      > .row:nth-child(7),
      > .row:nth-child(8),
      > .row:nth-child(9),
      .row:nth-child(10),
      .row:nth-child(11) {
        flex-direction: column;

        > span:first-child {
          width: 100%;
        }

        > div:last-child,
        > span:last-child {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
}
