@import "src/styles/references/index.scss";

.vt-dashboard {
  margin: auto auto;
  width: 87vw;
  height: 100vh;
  max-width: 1920px;
  max-height: 1080px;
  overflow-y: scroll;
  scrollbar-width: none;
  margin-top: 80px;
  padding-bottom: 120px;
  @include vt-pre-lg {
    scrollbar-width: initial;
    max-height: unset;
    overflow-y: visible;
  }

  .vt-sidebar {
    @include vt-pre-md {
      display: none !important;
    }
  }

  @include vt-pre-md {
    margin: 80px auto !important;
  }
  // @include vt-pre-xl {
  //   width: 100vw !important;
  // }

  @include vt-pre-lg {
    width: calc(100% - 60px);
    margin: 0 auto;
    height: auto;
  }

  @include vt-pre-md {
    width: 95%;
    margin: 0 auto;
    height: auto;
  }
}
