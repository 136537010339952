@import "src/styles/references/index.scss";

.vt-dashboard-cash-in-settings {
  width: 100%;
  height: auto;
  margin: 0 auto;

  .title {
    display: flex;
    justify-content: space-around;

    > h3 {
      font-size: 16px;
      color: #000;
      padding-right: 48px;
    }

    .vt-switch-row {
      margin-right: 16px;
    }

    div:last-child {
      display: flex;
      flex-direction: column;
    }

    .offline-deposit-header {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  .cash-in-settings-list {
    display: flex;
    justify-content: start;
    width: 100%;
    .cash-in-setting-item-card:first-child {
    }
  }
}

.cash-in-setting-item-card {
  display: flex;
  flex-direction: column;
  padding: 30px;
  margin: 0 auto;
  background: $white;
  height: auto;

  .ant-row {
    height: max-content;
    flex-direction: column;
  }

  .ant-row:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 8px;
    row-gap: 24px;
    .cash-in-setting-info {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .bank-accounts {
      display: flex;
      flex-direction: column;

      margin-top: 24px;

      &:first-child {
        margin-top: 0;
      }
      .bank-item {
        display: flex;
        flex-direction: column;

        .ant-dropdown-trigger {
          width: 100%;
          max-width: unset;

          @include vt-pre-lg {
            margin-right: 0 !important;
          }

          button {
            @include vt-pre-lg {
              max-width: unset;
              width: 100% !important;
              margin-top: 16px;
            }
          }
        }

        .vt-input {
          width: 320px;
          margin-top: 24px;
        }
        .vt-switch-row {
          margin-top: 24px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          row-gap: 8px;
          label {
            font-size: 12px;
          }
          .ant-switch {
            width: max-content;
            margin-left: 0px;
          }
        }
      }
    }

    .vt-button {
      width: max-content;
      min-width: 130px;
      margin-top: 32px;

      button {
        height: 32px;
      }
    }
  }

  .vt-button {
    margin-top: 16px;
  }
}

.bank-dropdown {
  height: 300px;
  overflow-y: scroll;
}
