@import "src/styles/references/index.scss";

.vt-dashboard-notifications {
  .row {
    display: flex;
    justify-content: flex-end;
    margin: 0 24px;

    .vt-button {
      margin: 0 24px;
      margin-left: 0 !important;
    }
  }

  .filter-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    margin-top: 16px;

    @include vt-pre-lg {
      flex-direction: column;
      background: $white;
      border-top: 1px solid $table-border;
    }

    > .ant-divider {
      display: none;
    }

    .buttons,
    .fields {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .buttons {
      width: 100%;
      justify-content: flex-end;

      @include vt-pre-lg {
        justify-content: space-between;
        margin-bottom: 16px;
      }

      > span {
        cursor: pointer;
        width: max-content;
        color: $danger;
        min-width: 60px;
      }

      > .vt-button {
        min-width: unset;
        width: 100%;
        margin: 0 0 0 16px;

        @include vt-pre-lg {
          margin: 0 auto;
        }

        &:last-child {
          margin-right: 16px;

          button {
            background: $danger-badge;
            color: $danger;
            border: 1px solid $danger;
          }
        }

        button {
          font-weight: 500;
        }
      }
    }

    .fields {
      width: 100%;
      @include vt-pre-lg {
        flex-direction: column;
      }

      > div {
        min-width: 130px;
        width: 100%;
        color: $info;
        border-radius: 10px;
        margin-left: 16px;

        @include vt-pre-lg {
          margin-left: 0;
        }

        &:last-child:not(.vt-dropdown) {
          margin-right: 16px;

          img {
            width: 25px;
            height: 25px;
            margin-left: 8px;
          }
        }
      }

      &:first-child {
        @include vt-pre-lg {
          margin-top: 16px;
        }
      }

      .vt-dropdown {
        img {
          width: 25px;
          height: 25px;
          margin-left: 8px;
        }
      }

      .vt-calendar {
        cursor: pointer;

        .vt-input {
          .input-field {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 16px;
            background: $white;

            input {
              cursor: pointer;
            }

            svg {
              path {
                fill: $primary;
              }
            }
          }
        }
      }
    }
  }

  .column-date {
    span {
      display: inline-block;
      width: 100%;
    }

    span:last-child {
      font-size: 10px;
      color: $medium-gray;
    }
  }

  height: max-content;

  .message-column {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
