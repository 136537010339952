@import "src/styles/references/index.scss";

.vt-dashboard-automation-settings {
  .card-body {
    display: flex;
    align-items: center;

    .automation-item {
      .card-body {
        row-gap: 24px;
        flex-direction: inherit;
        width: 85% !important;

        @include vt-pre-lg {
          width: 100% !important;
        }

        .switches {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .ant-switch {

          display: flex;
          align-items: center;
          width: max-content;
          .ant-switch-inner {
            font-size: 14px;
          }
        }

      }
    }
    .vt-input {
      .input-field {
        margin-bottom: 32px;
      }
    }
    .vt-button {
      height: 32px;
    }
  }
}
