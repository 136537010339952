@import "src/styles/references/index.scss";

.sidebar-main > .sidebar-item {
  position: relative;
  margin-right: 0px;
  padding: 4px;
  min-width: 166px;
  width: 168px;
  height: auto;
  max-width: 210px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  cursor: pointer;
  justify-content: flex-start;

  @include vt-pre-lg {
    width: 100%;
    max-width: unset;
    padding: 0 16px;
  }

  .content svg {
    width: 18px !important;
    vertical-align: middle;
  }

  .content {
    .title {
      margin: 5px 10px;
    }
  }

  .head {
    > svg:last-child {
      transform: rotate(-90deg);
      &.expanded {
        transform: rotate(90deg);
      }

      &.open {
        path {
          fill: $white;
        }
      }

      path {
        fill: $gray-span;
      }
    }
  }

  &.active {
    background: linear-gradient(95.43deg, #3f62fe -25.7%, #678af5 112.1%);
    .content svg path {
      fill: #ffffff;
    }

    .title {
      color: $white;
    }
  }

  .head,
  .head .content {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 100%;
  }

  ul.item-body {
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin-top: 18px;

    li {
      padding: 5px;
      width: 100%;
      position: relative;
      display: flex;
      color: $gray-span;
      font-size: 14px;

      &:hover {
        color: $info;
      }
      &.active {
        background-color: $info;
        color: $white;
        width: 100%;
        margin: 0;
        border-radius: 5px;
      }
    }
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  .title {
    margin-right: 13px;
    color: #859abc;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0em;
    width: max-content;
    display: flex;
    align-items: center;
  }
  .vt-badge {
    font-size: 8px;
    margin: 0px 6px;

    div {
      padding: 2px 10px !important;
    }
    img {
      display: none;
    }
  }
}

.sidebar-main > .sidebar-item:not(.filled) {
  .content {
    svg {
      rect {
        fill: #ffffff;
      }

      path {
        fill: #859abc;
      }
    }
  }
}

.sidebar-main > .sidebar-item.active {
  .content {
    svg {
      path {
        fill: #ffffff !important;
      }
    }
  }
}

.sidebar-main > .sidebar-item:nth-child(5).active {
  .content {
    svg {
      rect {
        fill: #ffffff;
      }

      path {
        fill: #859abc;
      }
    }
  }
}
