@import "src/styles/references/index.scss";
.user-carts-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  .row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .carts {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 38px auto;
    flex-direction: column;
    row-gap: 16px;
    .cart-item {
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-radius: 8px;
      background: linear-gradient(0deg, #ffffff, #ffffff), #f9fcff;
      box-shadow: 0px 0px 20px 0px rgb(19 42 43 / 8%);
      padding: 8px 24px !important;
      cursor: pointer;
      width: 100%;
      .bank-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-left: 8px;
      }
      .cart-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 8px;

        span {
          font-family: monospace !important;
        }
      }
      .ant-tag {
        border-radius: 8px;
        padding: 2px 8px;
      }
      .actions {
        display: flex;
        flex-direction: row!important;
        align-items: baseline;
        justify-content: center;
        padding-right: 8px;
        gap: 4px;

        span {
          cursor: pointer;
          &:hover {
            color: $info;
          }
        }
        span:first-child {
          margin-bottom: 8px;
        }
      }
    }
  }
}

.edit-user-cart-modal,
.create-user-cart-modal {
  .checkbox {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    span:last-child {
      margin-right: 5px;
    }
  }
}
