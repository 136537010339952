@import "src/styles/references/index.scss";

.vt-dashboard-market {
  padding: 30px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  column-gap: 50px;
  background: $white;
  border-radius: 20px;
  h3 {
    margin-bottom: 24px;
  }

  > .card-body {
    margin: 0;
    width: 100%;
  }

  .filter-row,
  .action-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;

    @include vt-pre-lg {
      flex-direction: column;
    }

    > .ant-divider {
      @include vt-pre-lg {
        display: none;
      }
    }

    .buttons,
    .fields {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .buttons {
      width: 100%;
      justify-content: flex-end;

      @include vt-pre-lg {
        justify-content: space-between;
        margin-bottom: 16px;
      }

      > span {
        cursor: pointer;
        width: max-content;
        color: $danger;
        min-width: 60px;
      }

      > .vt-button {
        min-width: unset;
        width: 100%;
        margin: 0 0 0 16px;

        @include vt-pre-lg {
          margin: 0 auto;
        }

        &:last-child {
          margin-right: 16px;

          button {
            background-color: $danger-badge;
            color: $danger;
            border: 1px solid $danger;
          }
        }

        button {
          font-weight: 500;
        }
      }
    }

    .fields {
      width: 100%;
      @include vt-pre-lg {
        flex-direction: column;
      }

      > div {
        min-width: 130px;
        width: 100%;
        color: $info;
        border-radius: 10px;
        margin-left: 16px;

        @include vt-pre-lg {
          margin-left: 0;
        }

        &:last-child:not(.date) {
          margin-right: 16px;

          img {
            width: 25px;
            height: 25px;
            margin-left: 8px;
          }
        }
      }

      &:first-child {
        @include vt-pre-lg {
          margin-top: 16px;
        }
      }

      .vt-dropdown {
        img {
          width: 25px;
          height: 25px;
          margin-left: 8px;
        }

        span {
          font-size: 10px;
        }

        &:first-child {
          ul {
            height: max-content;
          }
        }
      }

      .vt-calendar {
        cursor: pointer;

        .vt-input {
          .input-field {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 16px;
            background-color: $white;

            input {
              cursor: pointer;
            }

            svg {
              path {
                fill: $info;
              }
            }
          }
        }
      }
    }
  }

  .action-row {
    justify-content: end;

    .vt-button,
    .vt-dropdown {
      margin: 0;
      margin-right: 24px;
    }

    > .vt-button {
      max-width: unset;
      width: max-content;
    }

    > span,
    > p {
      color: $gray-text;
      margin-right: 24px;
    }
  }


  .market-table {
    margin-top: 32px;

    .editable-cell-input {
      min-width: 40px;

      input {
        padding: 8px;
      }
    }

    .edit-priority-icon.disabled {
      opacity: 0.5;
    }

    .rdt_TableCell[data-column-id="symbol"] {
      img {
        width: 25px;
        height: 25px;
        margin-left: 6px;
      }
      div {
        font-family: monospace !important;
      }
    }
    header {
      flex-direction: row;
    }
  }

  .row {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .vt-button {
      margin: 0 24px;
    }
  }

  .roles-table {
  }
}
