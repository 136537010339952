/**
*
*	Name:			Anjoman Fonts
*	Version:			2.0
*	Author:			Moslem Ebrahimi (moslemebrahimi.com)
*	Created on:		April 4, 2020
*	Updated on:		April 4, 2020
*	Website:			http://fontiran.com
*	Copyright:		Commercial/Proprietary Software
--------------------------------------------------------------------------------------
فونت انجمن یک نرم افزار مالکیتی محسوب می شود. جهت آگاهی از قوانین استفاده از این فونت ها لطفا به وب سایت (فونت ایران دات کام) مراجعه نمایید
--------------------------------------------------------------------------------------
Anjoman fonts are considered a proprietary software. To gain information about the laws regarding the use of these fonts, please visit www.fontiran.com 
--------------------------------------------------------------------------------------
This set of fonts are used in this project under the license: (.....)
------------------------------------------------------------------------------------- ../Fa/-
*	
**/

@font-face {
  font-family: Anjoman;
  font-style: normal;
  font-weight: 100;
  src: url("../Fa/eot/Anjoman-FaNum-Thin.eot");
  src: url("../Fa/eot/Anjoman-FaNum-Thin.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../Fa/woff2/Anjoman-FaNum-Thin.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("../Fa/woff/Anjoman-FaNum-Thin.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: Anjoman;
  font-style: normal;
  font-weight: 200;
  src: url("../Fa/eot/Anjoman-FaNum-ExtraLight.eot");
  src: url("../Fa/eot/Anjoman-FaNum-ExtraLight.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../Fa/woff2/Anjoman-FaNum-ExtraLight.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("../Fa/woff/Anjoman-FaNum-ExtraLight.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: Anjoman;
  font-style: normal;
  font-weight: 300;
  src: url("../Fa/eot/Anjoman-FaNum-Light.eot");
  src: url("../Fa/eot/Anjoman-FaNum-Light.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../Fa/woff2/Anjoman-FaNum-Light.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("../Fa/woff/Anjoman-FaNum-Light.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: Anjoman;
  font-style: normal;
  font-weight: 500;
  src: url("../Fa/eot/Anjoman-FaNum-Medium.eot");
  src: url("../Fa/eot/Anjoman-FaNum-Medium.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../Fa/woff2/Anjoman-FaNum-Medium.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("../Fa/woff/Anjoman-FaNum-Medium.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: Anjoman;
  font-style: normal;
  font-weight: 600;
  src: url("../Fa/eot/Anjoman-FaNum-SemiBold.eot");
  src: url("../Fa/eot/Anjoman-FaNum-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../Fa/woff2/Anjoman-FaNum-SemiBold.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("../Fa/woff/Anjoman-FaNum-SemiBold.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: Anjoman;
  font-style: normal;
  font-weight: 750;
  src: url("../Fa/eot/Anjoman-FaNum-ExtraBold.eot");
  src: url("../Fa/eot/Anjoman-FaNum-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../Fa/woff2/Anjoman-FaNum-ExtraBold.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("../Fa/woff/Anjoman-FaNum-ExtraBold.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: Anjoman;
  font-style: normal;
  font-weight: 800;
  src: url("../Fa/eot/Anjoman-FaNum-UltraBold.eot");
  src: url("../Fa/eot/Anjoman-FaNum-UltraBold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../Fa/woff2/Anjoman-FaNum-UltraBold.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("../Fa/woff/Anjoman-FaNum-UltraBold.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: Anjoman;
  font-style: normal;
  font-weight: 850;
  src: url("../Fa/eot/Anjoman-FaNum-Black.eot");
  src: url("../Fa/eot/Anjoman-FaNum-Black.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../Fa/woff2/Anjoman-FaNum-Black.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("../Fa/woff/Anjoman-FaNum-Black.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: Anjoman;
  font-style: normal;
  font-weight: 900;
  src: url("../Fa/eot/Anjoman-FaNum-Heavy.eot");
  src: url("../Fa/eot/Anjoman-FaNum-Heavy.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../Fa/woff2/Anjoman-FaNum-Heavy.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("../Fa/woff/Anjoman-FaNum-Heavy.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: Anjoman;
  font-style: normal;
  font-weight: 1000;
  src: url("../Fa/eot/Anjoman-FaNum-SuperHeavy.eot");
  src: url("../Fa/eot/Anjoman-FaNum-SuperHeavy.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../Fa/woff2/Anjoman-FaNum-SuperHeavy.woff2")
      format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/
      url("../Fa/woff/Anjoman-FaNum-SuperHeavy.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: Anjoman;
  font-style: normal;
  font-weight: bold;
  src: url("../Fa/eot/Anjoman-FaNum-Bold.eot");
  src: url("../Fa/eot/Anjoman-FaNum-Bold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../Fa/woff2/Anjoman-FaNum-Bold.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("../Fa/woff/Anjoman-FaNum-Bold.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: Anjoman;
  font-style: normal;
  font-weight: normal;
  src: url("../Fa/eot/Anjoman-FaNum-Regular.eot");
  src: url("../Fa/eot/Anjoman-FaNum-Regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../Fa/woff2/Anjoman-FaNum-Regular.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("../Fa/woff/Anjoman-FaNum-Regular.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
