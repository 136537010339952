@import "src/styles/references/index.scss";

.user-info-surveys-table {
  div {
    img.thumbnail {
      width: 50px;
      height: 50px;
    }
  }

  .row {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .vt-button {
      margin: 0 24px;
    }
  }

  .user-info-surveys-table {
  }
}
