@import "src/styles/references/index.scss";

.vt-sidebar {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  height: 100%;
  font-size: 18px;
  min-width: 213px;
  z-index: 999;

  @include vt-pre-lg {
    height: calc(100% - 80px);
    width: 100%;
    position: fixed;
    transition: 0.5s ease-out;
    justify-content: flex-start;
    overflow-y: scroll;
    margin-top: 0;
    background: $white;
    z-index: 9;
  }

  &.disActive {
    right: 0;
    @include vt-pre-lg {
      right: -100%;
    }
  }

  // @include vt-pre-lg {
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   background: $bg;
  //   transition: 0.5s ease-in-out;
  //   &.disActive {
  //     right: -100%;
  //   }
  // }

  .sidebar-head {
    margin-right: 10px;
    margin-bottom: 32px;
    margin-top: 10px;

    img {
      width: auto;
      height: 50px;
    }
  }

  .sidebar-main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin: 0 auto;
    overflow-x: hidden;
    row-gap: 8px;
    height: 100%;
    overflow-y: scroll;

    @include vt-pre-lg {
      width: 100%;
      margin: 100px 0 28px;
      margin-top: 34px !important;
      margin-bottom: 0 !important;
      width: 100%;
      padding-left: 24px;
    }

    &.mobile-show {
      display: none;
      @include vt-pre-lg {
        display: flex;
      }
    }

    &.desktop-show {
      @include vt-pre-lg {
        display: none;
      }
    }
  }

  .sidebar-footer {
    padding: 16px 30px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 20px;
    color: $gray-span;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0em;

    @include vt-pre-lg {
      width: 100%;
      padding-top: 28px;
      padding-bottom: 40px;
    }

    .sidebar-item {
      cursor: pointer;
    }
  }

  .navbar-user {
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
    padding-left: 20px;
    display: none;

    @include vt-pre-lg {
      display: flex;
      padding: 16px 0 16px 24px;
      width: 100%;
      justify-content: space-between;
    }

    .mobile-leftside {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;

      @include vt-pre-lg {
        order: 2;
      }

      svg {
        cursor: pointer;
        display: none;
        @include vt-pre-lg {
          display: block;
        }
      }
    }

    .navbar-profile {
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 10px;

      @include vt-pre-lg {
        order: 1;
      }

      .dropdown {
        > span {
          @include vt-pre-lg {
            font-weight: 500;
          }
        }

        > h4 {
          display: none;
          @include vt-pre-lg {
            display: block;
            font-size: 14px;
            color: $gray-span;
          }
        }
      }
    }

    .navbar-profile > .avatar {
      background: $primary;
      border-radius: 5px;

      > img {
        width: 40px;
        height: 40px;
      }
    }
  }

  .sidebar-footer > .sidebar-item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 10px;
    font-size: 14px;
    position: absolute;
    bottom: 4px;
    right: 24px;


    .switch {
      position: relative;
      margin-right: 10px;
      width: 45px;
      height: 24px;
      display: inline-block;

      &.disabled {
        opacity: 0.5;
      }

      > input {
        opacity: 0;
        visibility: hidden;

        &:checked + .slider {
          background: $night-switch-bg;

          &::before {
            transform: translate(18px, -50%);
          }
        }
      }

      .slider {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: $c-white;
        border-radius: 34px;
        transition: 0.4s;
        cursor: pointer;

        &::before {
          content: "";
          position: absolute;
          height: 19px;
          width: 19px;
          top: 50%;
          transform: translateY(-50%);
          left: 4px;
          background: $white;
          border-radius: 50%;
          transition: 0.4s;
        }
      }
    }
  }
}
