@import "src/styles/references/index.scss";

.vt-container {
  display: flex;
  background-color: $bg;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  margin: auto;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background: #c9cfd8;
  }

  ::-webkit-scrollbar-track {
    background: #ffffff;
  }
  @include vt-pre-lg {
    padding: 0 2.5%;
    height: 100%;
  }

  .filter-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    @include vt-pre-lg {
      flex-direction: column;
    }

    > .ant-divider {
      @include vt-pre-lg {
        display: none;
      }
    }

    .buttons,
    .fields {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .buttons {
      width: 100%;
      justify-content: flex-end;

      @include vt-pre-lg {
        justify-content: space-between;
        flex-direction: column;
        margin: 16px 0;
      }

      > span {
        cursor: pointer;
        width: max-content;
        color: $danger;
        min-width: 60px;

        @include vt-pre-lg {
          margin-top: 16px;
        }
      }

      > .vt-button {
        min-width: unset;
        width: 100%;
        margin: 0 0 0 16px;

        @include vt-pre-lg {
          margin: 0 auto;
          max-width: unset;
        }

        &:last-child {
          margin-right: 16px;

          button {
            background-color: $danger-badge;
            color: $danger;
            border: 1px solid $danger;
          }
        }
        button {
          font-weight: 500;
        }
      }
    }
    .fields {
      width: 100%;
      @include vt-pre-lg {
        flex-direction: column;
      }

      button {
        width: max-content !important;
        justify-content: space-between;
        min-width: 140px;
      }
      > div {
        min-width: 130px;
        width: 100%;
        color: $info;
        border-radius: 10px;
        margin-left: 16px;

        @include vt-pre-lg {
          margin-left: 0;
        }

        &:last-child:not(.date) {
          img {
            width: 25px;
            height: 25px;
            margin-left: 8px;
          }
        }
      }

      &:first-child {
        @include vt-pre-lg {
          margin-top: 16px;
        }
      }

      .vt-dropdown {
        img {
          width: 25px;
          height: 25px;
          margin-left: 8px;
        }
        span {
          font-size: 10px;
        }
        &:first-child {
          ul {
            height: max-content;
          }
        }
      }

      .vt-calendar {
        cursor: pointer;
        .vt-input {
          .input-field {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 16px;
            background-color: $white;

            input {
              cursor: pointer;
            }

            svg {
              path {
                fill: $info;
              }
            }
          }
        }
      }

      .ant-dropdown-trigger {
        width: 100%;
        max-width: unset;

        @include vt-pre-lg {
          margin-right: 0 !important;
        }

        button {
          @include vt-pre-lg {
            max-width: unset;
            width: 100% !important;
            margin-top: 16px;
          }
        }
      }

      > .vt-input {
        @include vt-pre-lg {
          margin-right: unset !important;
        }
      }
    }
  }
}
