@import "src/styles/references/index";

.vt-bottom-menu {
  position: fixed;
  display: none;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: $white;

  @include vt-pre-lg {
    display: flex;
  }
}
