@import "styles/references/index.scss";

.vt-support-detail {
  margin: 70px 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  @include vt-pre-lg {
    margin-top: 0;
  }

  .details {
    width: 100%;
    border-radius: 20px;
    width: 100%;

    @include vt-pre-lg {
      padding-left: 0;
      padding-right: 0;
      background: transparent;
    }
    > .card-body {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      margin: 0;

      > .head {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include vt-pre-lg {
          display: none;
        }

        .buttons {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .vt-button {
            max-width: 120px;
            margin: 0 !important;

            &:first-child {
              max-width: unset;
              button {
                max-width: unset;
                width: 100%;
                height: 38px;

                span {
                  width: max-content;
                }
              }
            }

            &:last-child {
              margin-right: 16px !important;
            }
          }
        }
      }

      > .data-row {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        > div {
          border: 1px solid $table-border;
          border-radius: 10px;
          padding: 8px 16px;
          width: 70%;
          max-width: 650px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          @include vt-pre-lg {
            flex-direction: column;
            width: 100%;
            background: $white;
          }

          .col {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &:last-child {
              margin-right: 32px;

              @include vt-pre-lg {
                margin-right: 0;
              }
            }

            > div {
              display: flex;
              justify-content: space-between;
              align-items: center;

              p,
              span {
                width: max-content;
                font-size: 14px;
                margin-bottom: 0;
              }

              span {
                font-weight: 500;
                margin-right: 3px;
              }
            }
          }

          .vt-badge {
            > div {
              height: 38px;

              img {
                order: 1;
              }
            }
          }
        }
      }

      .chat {
        width: 100%;
        button {
          max-width: 180px;
          max-height: 42px;
          margin-left: 0px;
          margin-right: auto;
          span {
            font-size: 16px;
          }
        }
        .messages {
          margin-top: 24px;
          position: relative;
          border-radius: 10px;
          background: $primary;
          padding: 16px 16px;

          .ant-empty-description {
            color: $white;
          }

          > .overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: url("./../../../../../public/assets/images/slides/mask.svg");
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 0;
          }

          > .message {
            margin: 16px 0;
            position: relative;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            z-index: 1;

            .content {
              padding: 8px 16px;
              background: $white-cyan;
              border-radius: 10px;
              margin-left: 16px;
              color: $bold-span;
              max-width: 50%;

              @include vt-pre-lg {
                max-width: unset;
              }
            }

            &.self {
              justify-content: flex-start;

              .content {
                background: $white;
                margin: 0;
                margin-right: 16px;
              }
            }

            .avatar {
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-direction: column;
              color: $white;

              img {
                margin-top: 2px;
              }
            }
          }
        }
        textarea {
          margin-top: 24px;
          border-radius: 10px;
          min-height: 100px;
          max-height: 100px;
          border-color: $table-border;
        }

        > .browse-field {
          margin-top: 24px;
          position: relative;
          border: 1px solid $table-border;
          padding: 5px;
          width: 100%;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;

          label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            top: -24px;
            left: 0;
            width: 100%;

            > span:first-child {
              display: flex;
              justify-content: space-between;
              align-items: center;
              column-gap: 3px;
              span {
                font-size: 12px;
              }
            }

            > span:last-child {
              display: flex;
              justify-content: space-between;
              align-items: center;
              column-gap: 3px;
            }
          }

          .browse {
            display: flex;
            justify-content: center;
            width: 100%;
            height: 100%;
            .avatar-uploader {
              width: 100%;
              height: 100%;
              .ant-upload {
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                border: 0.6px dashed $transparent-blue;
                border-spacing: 10px;
                border-radius: 5px;

                button {
                  background: transparent;
                  border: 0;
                  color: $info;
                }
              }
            }
          }
        }

        > .vt-button {
          width: 100%;
          max-width: unset;
          margin: 0;
          margin-top: 24px;

          button {
            background: linear-gradient(95.43deg, $info -25.7%, $access 112.1%);
            height: 72px !important;
            font-size: 20px;
            font-weight: 500;

            @include vt-pre-lg {
              width: 100%;
              max-width: unset;
            }
          }
        }
      }
    }
  }
}
