@import "src/styles/references/index.scss";

.vt-dashboard-surveys-edit {
  padding: 30px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  column-gap: 50px;
  background: $white;
  border-radius: 20px;
  h3 {
    margin-bottom: 24px;
  }

  form {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 8px;

    #selfie-photo > span,
    #hw-photo > span,
    #doc-photo > span {
      width: 100%;
    }

    .input-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 30px;
      width: 100%;

      @include vt-pre-lg {
        flex-direction: column;
      }
    }

    .browse-field {
      position: relative;
      border: 1px solid $table-border;
      padding: 5px;
      width: 100%;
      height: max-content;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;

      @include vt-pre-lg {
        width: 100%;
        margin-top: 24px;
      }

      &:last-child {
        @include vt-pre-lg {
          margin-top: 32px;
        }
      }

      label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        top: -28px;
        left: 0;
        width: 100%;

        > span {
          @include vt-pre-lg {
            font-size: 10px;
          }
        }

        > span:first-child {
          display: flex;
          justify-content: space-between;
          align-items: center;
          column-gap: 3px;

          span {
            font-size: 12px;
          }
        }

        > .sample {
          display: flex;
          justify-content: space-between;
          align-items: center;
          column-gap: 3px;
          color: $white;
          border-radius: 10px;
          background: $primary;
          padding: 3px;
        }
      }

      .browse {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;

        .selfie-uploader, .handwrite-uploader,
        .document-uploader, .doc-uploader,
        {
          width: 100%;
          height: 100%;

          .ant-upload {
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            border: 0.6px dashed $transparent-blue;
            border-spacing: 10px;
            border-radius: 5px;

            button {
              background: transparent;
              border: 0;
              color: $info;

              span.mobile-show {
                display: none;
                @include vt-pre-lg {
                  display: inline-block;
                }
              }

              span.desktop-show {
                @include vt-pre-lg {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    > .row {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    > .row:last-child {
      margin-top: 45px;

    }

    .buttons {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      justify-content: flex-end;

      @include vt-pre-lg {
        justify-content: space-between;
        margin-bottom: 16px;
      }

      > span {
        cursor: pointer;
        width: max-content;
        color: $danger;
        min-width: 60px;
      }

      > .vt-button {
        min-width: unset;
        width: 100%;
        margin: 0 0 0 16px;

        @include vt-pre-lg {
          margin: 0 auto;
        }

        &:last-child {
          margin-right: 16px;

          button {
            background-color: $danger-badge;
            color: $danger;
            border: 1px solid $danger;
          }
        }
        button {
          font-weight: 500;
        }
      }
    }
  }
}
