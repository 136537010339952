@import "src/styles/references/index.scss";

.vt-dashboard-popups-edit-popup {
  padding: 30px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  column-gap: 50px;
  background: $white;
  border-radius: 20px;
  h3 {
    margin-bottom: 24px;
  }

  form {
    flex-wrap: wrap;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: unset;
    padding: 8px;

    .textarea {
      position: relative;
      width: 100%;

      textarea.ant-input {
        width: 100%;
        min-height: 250px;
        max-height: 250px;
      }

      label {
        font-size: 12px;
        position: absolute;
        top: -18px;
      }
    }

    > .ant-row {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      margin-top: 24px;

      &:first-child {
        margin-top: 0;
      }

      .vt-input {
        width: 100%;
      }

      .ant-col {
        width: 50%;
      }

      .ant-col:last-child {
        margin-right: 24px;
      }

      .vt-button {
        margin-top: 24px;
        width: 50%;
      }
    }

    .browse-field {
      position: relative;
      border: 1px solid $table-border;
      padding: 5px;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 10px;

      .ant-image {
        justify-self: start;
        margin-left: auto;
        margin-bottom: 8px;
        width: 100px;
        height: 100px;
      }

      label {
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        top: -24px;
        left: 0;
        width: 100%;

        > span:first-child {
          display: flex;
          justify-content: space-between;
          align-items: center;
          column-gap: 3px;
          span {
            font-size: 12px;
          }
        }

        > span:last-child {
          display: flex;
          justify-content: space-between;
          align-items: center;
          column-gap: 3px;
        }
      }

      .browse {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        .file-uploader {
          width: 100%;
          height: 100%;
          .ant-upload {
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            border: 0.6px dashed $transparent-blue;
            border-spacing: 10px;
            border-radius: 5px;

            button {
              background: transparent;
              border: 0;
              color: $info;
            }
          }
        }
      }
    }

    .select {
      position: relative;
      width: 100%;

      label {
        font-size: 12px;
        position: absolute;
        top: -18px;
      }

      .ant-select {
        width: 100%;
        .ant-select-selector {
          height: 54px;
          border-radius: 10px;
        }
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @include vt-pre-lg {
        justify-content: space-between;
      }

      .vt-button {
        margin: 0;
        margin-top: 24px;

        &:last-child {
          margin-right: 24px;
        }

        button {
          height: 48px !important;
        }
      }
    }

    > .vt-input {
      width: 100%;
      margin-top: 24px;
      flex: 50%;
      padding: 2px;
    }
  }
}
