@import "src/styles/references/index.scss";

.vt-dashboard-permissions {
  padding: 30px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  column-gap: 50px;
  background: $white;
  border-radius: 20px;
  h3 {
    margin-bottom: 24px;
  }

  > .card-body {
    margin: 0;
    width: 100%;
  }

  .permissions-table {
  }
}
