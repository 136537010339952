.vt-dashboard-fiat-limits-settings {
    .card-body {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 8px;

        .fiat-limit {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 24px;
            margin-bottom: 16px;
            padding: 16px;
            width: fit-content;

            .ant-divider {
                &:last-child {
                    display: none;
                }
            }
        }
    }
}