@import "src/styles/references/index.scss";

.vt-user-referral-reports {
  padding: 30px;
  width: 100%;
  border-radius: 20px;

  h3 {
    margin-bottom: 24px;
  }

  @include vt-pre-lg {
    .ant-row {
      display: unset !important;

      .ant-col-6,
      .ant-col-12 {
        max-width: unset !important;
      }
    }
  }

  .vt-panel-card {
    .head {
      flex-direction: row !important;
    }
  }

  .gateways-table {
    width: 25%;
    margin-right: 0;
    margin-left: auto;
  }

  .vt-panel-card {
    height: 128px !important;
    margin-bottom: 24px;
    display: block !important;
    cursor: pointer;
    transition: 0.7s;

    &.active {
      height: 180px !important;
    }

    &:hover,
    &.active {


      background-color: $gray-span;
      transition: 0.7s;
      color: $white;

      .head h3 {
        color: $white !important;
      }

      .head span {
        color: $white !important;
      }
    }

    .head {
      align-items: center;
      margin-bottom: 0px;

      span {
        font-size: 32px;
        color: $info;
      }

      h3 {
        margin-bottom: 0px;
      }
    }

    .card-body {
      font-weight: normal;
      margin: 24px auto;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .divider {
        @include vt-pre-lg {
          display: none;
        }
      }

      .prices,
      .users-count {
        display: flex;
        column-gap: 8px;
        justify-content: space-around;
        @include vt-pre-lg {
          flex-direction: column;
        }
      }
    }
  }
}
