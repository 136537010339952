@import "src/styles/references/index.scss";

.vt-dashboard-kucoin {
  padding: 30px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  column-gap: 50px;
  background: $white;
  border-radius: 20px;

  h3 {
    margin-bottom: 24px;
  }

  > .card-body {
    margin: 0;
    width: 100%;
  }

  .history-table {
    margin: 0;

    .txid-col {
      display: flex;
      align-items: center;
    }

    .long-text {
      text-wrap: nowrap;
      max-width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;

    }

    [data-column-id="createTime"] {
      .column-date {
        span {
          display: inline-block;
          width: 100%;
        }

        span:last-child {
          font-size: 10px;
          color: $medium-gray;
        }
      }
    }

    [data-column-id="asset"] {
      img {
        width: 25px;
        height: 25px;
        margin-left: 6px;
      }

      span {
        font-family: monospace !important;
      }
    }

    header {
      flex-direction: row;
    }
  }

  .row {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .vt-button {
      margin: 0 24px;
    }
  }

  .roles-table {
  }
}
