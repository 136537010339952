@import "src/styles/references/index.scss";

.vt-dashboard-mannual-authinfo-detail {
  padding: 30px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  column-gap: 50px;
  background: $white;
  border-radius: 20px;
  overflow-y: scroll;
  margin-bottom: 150px;

  .card-body {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .ant-image {
      &:not(:first-child) {
        margin-right: 16px;
      }
    }

    .vt-input {
      flex: 1 0 21%; /* explanation below */
      margin: 5px;
      height: 100px;
    }
  }
  .ant-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > &.buttons {
      justify-content: flex-end;

      .vt-button {
        margin: 0;
        margin-right: 24px;
        margin-top: 24px;

        button {
          height: 48px !important;
        }
      }
    }

    textarea.ant-input {
      margin-top: 24px;
      width: 100%;
      min-height: 250px;
      max-height: 250px;
    }

    &.files {
      img {
        margin-top: 24px;
        margin-left: 16px;
        width: 420px;
        height: 420px;
      }
    }

    .ant-col {
      min-width: 100px;

      > span {
        color: $gray-span;
      }
    }

    .vt-button {
      margin-top: 24px;
      width: 50%;
    }
  }
}
