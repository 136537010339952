@import "src/styles/references/index.scss";

.edit-user-data-modal {
  .google-auth {
    width: 100%;
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &.code {
      .input {
        direction: ltr;
        > div {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          > input {
            margin: 0 10px;
            border: 1px solid $table-border;
            border-radius: 10px;
            max-height: 48px;

            @include vt-pre-md {
              margin: 0 5px;
            }
          }
        }
      }
      .input.has-error {
        input {
          border-color: $danger;
        }
      }

      .input.has-error {
        input {
          border-color: $danger;
        }
      }

      .stats {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin: 10px 0;

        span.resend {
          cursor: pointer;
          color: $info;
        }

        .timer {
          justify-self: flex-start;
          display: flex;
          justify-content: center;
          align-items: center;

          > div {
            left: auto !important;
            top: auto !important;
            > svg {
              display: none;
            }
            > div {
              position: relative !important;
              height: 30px !important;
              width: 30px !important;
            }
          }
        }
        .errors {
          display: flex;
          justify-self: flex-start;
          justify-content: flex-start;
          align-items: center;
          column-gap: 4px;
          color: $danger;
          font-size: 12px;
          min-width: 50px;
        }
        > span {
          min-width: 50px;
        }
      }

      .timer {
        justify-self: center;
        display: flex;
        justify-content: center;
        align-items: center;

        > div {
          left: auto !important;
          top: auto !important;
          > svg {
            display: none;
          }
          > div {
            position: relative !important;
            height: 30px !important;
            width: 30px !important;
          }
        }
      }
      .change-number {
        margin-top: 10px;
        color: $info;
        cursor: pointer;
      }
    }

    &.app-code {
      .input {
        direction: ltr;
        > div {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          > input {
            margin: 0 5px;
            border: 1px solid $table-border;
            border-radius: 10px;
            max-height: 48px;

            @include vt-pre-md {
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
}

.vt-user-details {
  padding: 30px;
  width: 100%;
  height: auto;
  min-height: 80vh;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  column-gap: 50px;
  background: $white;
  border-radius: 20px;

  .head {
    h3 {
      margin-bottom: 24px;
    }

    .ant-segmented {
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      border-radius: 8px;

      .ant-segmented-group {
        display: flex;
        justify-content: space-between;

        .ant-segmented-item {
          min-width: calc(100% / 7);
          padding: 8px;
          border-radius: 8px;
        }
      }
    }
  }
  .stage-content {
    width: 95%;
    margin: 0 auto;

    .ant-spin-container {
      display: flex;
      flex-wrap: wrap;
      row-gap: 34px;
      column-gap: 24px;
      margin-top: 58px;
      .vt-field {
        flex: 40%;
        width: 100%;
        display: flex;
        flex-direction: column;

        &:last-child {
          margin-right: 16px;
        }

        .withdrawal-disabled-button {
          max-width: unset;
          margin-top: 8px;

          button {
            max-width: unset;
          }
        }

        .vt-input,
        .ant-select {
          width: 100%;

          .ant-select-selector {
            height: 48px !important;
          }
        }
        button {
          max-width: 45px;
        }
      }
    }
    .ant-row {
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
    .vt-input {
      width: 35%;
    }
    .vt-button {
      margin-left: 0px;
    }

    .ant-col {
      width: 35%;

      .ant-select-multiple {
        width: 100%;
      }
      .ant-select {
        width: 100%;
      }
    }

    .user-carts-list {
      // height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 38px auto;

      .ant-spin-nested-loading {
        width: 100% !important;
      }
      .cart-item {
        display: flex;
        align-items: center;
        justify-content: space-around;
        border-radius: 8px;
        padding: 6px 0px;
        background: linear-gradient(0deg, #ffffff, #ffffff), #f9fcff;
        box-shadow: 0px 0px 20px 0px rgb(19 42 43 / 8%);
        cursor: pointer;
        width: 100%;
        .bank-info {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .cart-info {
          display: flex;
          flex-direction: column;
          align-items: end;

          span {
            font-family: monospace !important;
          }
        }
        .ant-tag {
          border-radius: 8px;
          padding: 2px 8px;
        }
        .actions {
          display: flex;
          flex-direction: column;

          span:first-child {
            margin-bottom: 8px;

            &:hover {
              cursor: pointer;
              color: $info;
            }
          }
        }
      }
    }
  }
}
