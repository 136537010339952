@import "src/styles/references/index.scss";

.vt-dashboard-bonus-edit {
  padding: 30px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  column-gap: 50px;
  background: $white;
  border-radius: 20px;
  h3 {
    margin-bottom: 24px;
  }

  form {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 8px;

    .buttons {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 1%;

      @include vt-pre-lg {
        justify-content: space-between;
      }

      .vt-button {
        margin: 24px 0 0 !important;

        &:last-child {
          margin-right: 24px;
        }

        button {
          height: 48px !important;
        }
      }
    }

    > .ant-row {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      margin-top: 24px;

      &:first-child {
        margin-top: 0;
      }

      .ant-dropdown-trigger {
        width: 100% !important;
        max-width: unset;
        margin: 0 !important;
        button {
        }
      }

      .vt-input {
        width: 100%;
      }

      .ant-col {
        width: 50%;
      }

      .ant-col:last-child {
        margin-right: 24px;
      }

      .vt-button {
        margin-top: 10px !important;
        width: 50%;
      }

      .ant-spin-nested-loading {
        min-width: 100%;

        label {
          font-size: 12px;
          position: absolute;
          top: -18px;
        }
      }
      .ant-switch {
        display: flex;
        align-items: center;
        width: max-content;
        .ant-switch-inner {
          font-size: 14px;
        }
      }

      .vt-switch-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 8px;
        label {
          font-size: 12px;
        }
        .ant-switch {
          width: max-content;
          margin-left: 0px;
        }
      }

      .ant-select {
        width: 100%;
        .ant-select-selector {
          min-height: 48px;
          border-radius: 10px;
        }
      }
    }

    textarea.ant-input {
      margin-top: 24px;
      width: 100%;
      min-height: 250px;
      max-height: 250px;
    }

    > .row {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &:last-child {
        justify-content: flex-end;

        .vt-button {
          margin: 0;
          margin-right: 24px;
          margin-top: 24px;

          button {
            height: 48px !important;
          }
        }
      }

      .vt-input {
        width: 50%;
      }

      .vt-input:last-child {
        margin-right: 24px;
      }

      .vt-button {
        margin-top: 24px;
        width: 50%;
      }
    }

    .markets-transfer {
      direction: ltr;
      margin-top: 24px;
      width: 100%;

      .ant-transfer-list-header-selected {
        direction: rtl;
      }

      .ant-transfer-list-content {
        direction: rtl;

        .ant-transfer-list-content-item-text {
          margin-right: 4px;
        }
      }

      .ant-transfer-list {
        direction: ltr;
        width: 50%;
        border-radius: 10px;
      }
    }

    > .vt-input {
      width: 100%;
      margin-top: 24px;
    }
  }
}
