@import "src/styles/references/index.scss";

.vt-dashboard-cash-in-management {
  padding: 30px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  column-gap: 50px;
  background: $white;
  border-radius: 20px;

  h3 {
    margin-bottom: 24px;
  }

  > .card-body {
    margin: 0;
    width: 100%;
  }

  .row {
    display: flex;
    justify-content: flex-end;
    margin: 0 24px;

    .vt-button {
      margin: 0 24px;
      margin-left: 0 !important;
    }
  }



  .cash-in-management-datatable {
    width: 100%;
    margin: 0;

    .column-createdBy {
      span {
        display: inline-block;
        width: 100%;
        font-size: 12px;
      }

      span:last-child {
        font-size: 10px;
        color: $medium-gray;
      }

      p {
        color: #a8a8a8;
        margin: 0;
        font-size: 13px;
      }
    }
    .column-date {
      span {
        display: inline-block;
        width: 100%;
      }
      span:last-child {
        font-size: 10px;
        color: $medium-gray;
      }
    }
    .actions {
      a {
        margin-left: 8px;
      }
    }
  }
}
