@import "src/styles/references/index.scss";

.vt-dashboard-gatewaysetting {
  padding: 30px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  column-gap: 50px;
  background: $white;
  border-radius: 20px;
  margin-bottom: 100px;
  h3 {
    margin-bottom: 24px;
  }

  .vt-input input[name="payeeAccount"],
  .vt-input input[name="account-id"],
  .vt-input input[name="password"] {
    font-family: monospace !important;
  }

  .card-body {
    margin: 0 auto;
  }
  form {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 8px;

    .card-body {
      @include vt-pre-lg {
        margin: 0;
        width: 100%;
        flex-direction: column;
      }
    }

    textarea.ant-input {
      margin-top: 24px;
      width: 100%;
      min-height: 250px;
      max-height: 250px;
    }

    > .vt-dropdown {
      margin-top: 24px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    > .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h5 {
        width: max-content;
        width: 20%;
      }

      > .switch,
      > .vt-input,
      > .ant-select {
        margin-top: 24px;
        width: 35%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:last-child {
          margin-right: 24px;
        }
      }
      &.col3 {
        align-items: start;

        .gateway-item {
          .switch,
          .vt-input {
            margin-top: 24px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:last-child {
              margin-right: 24px;
            }
          }
        }
      }

      > .ant-select {
        .ant-select-selector {
          height: 48px;
          border-radius: 10px;
        }
      }

      > .vt-button {
        margin: 0;
        margin-right: 24px;
        margin-top: 24px;

        button {
          height: 48px !important;
        }
      }

      &.buttons {
        justify-content: flex-end;

        .vt-button {
          margin: 0;
          margin-right: 24px;
          margin-top: 24px;

          button {
            height: 48px !important;
          }
        }
      }

      .vt-input {
        width: 35%;
      }

      .ant-select {
        width: 50%;
      }

      .vt-input:last-child {
        margin-right: 24px;
      }

      .vt-button {
        margin-top: 24px;
        width: 50%;
      }
    }

    .markets-transfer {
      direction: ltr;
      margin-top: 24px;
      width: 100%;

      .ant-transfer-list-header-selected {
        direction: rtl;
      }

      .ant-transfer-list-content {
        direction: rtl;

        .ant-transfer-list-content-item-text {
          margin-right: 4px;
        }
      }

      .ant-transfer-list {
        direction: ltr;
        width: 50%;
        border-radius: 10px;
      }
    }

    > .vt-input {
      width: 35%;
      margin-top: 24px;
    }
  }
}
