@import "src/styles/references/index";

.vt-input {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  .input-field {
    // background-color: $input;
    position: relative;
    border: 1px solid $table-border;
    border-radius: 10px;
    width: 100%;

    label {
      position: absolute;
      bottom: 50px;
      font-size: 12px;
      width: max-content;
      right: 0px;
    }

    input {
      outline: 0;
      border: 0;
      height: 48px;
      padding: 0px 10px;
      width: 100%;
      border-radius: 10px;
    }
    .input-icon {
      right: 8%;
    }
    .input-copy-icon {
      right: 2%;
    }
    .input-copy-icon,
    .input-icon {
      top: 30%;
      position: absolute;

      font-size: 18px;

      opacity: 0.5;
      &:hover {
        cursor: pointer;
        color: $primary;
      }
    }
  }
  .input-error,
  .input-helpertext,
  .input-greenhelpertext {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
    font-size: 10px;
    color: $danger;
    margin-top: 2px;
  }

  .input-greenhelpertext {
    color: $success;
  }

  .input-helpertext {
    color: $gray-span;
  }

  .input-adornment {
  }

  .prefix {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 8px;
    transform: translateY(1px);
  }

  .suffix {
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 8px;
  }
}
