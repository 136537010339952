@import "src/styles/references/index.scss";

.vt-dashboard-main {
  padding: 30px;
  width: 100%;
  border-radius: 20px;

  h3 {
    margin-bottom: 24px;
  }
  .filter-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    border-radius: 20px;
    background: lighten($bg, 100);
    height: 80px;

    @include vt-pre-lg {
      flex-direction: column;
      background: $white;
      border-top: 1px solid $table-border;
      width: calc(100%);
      height: auto;
      box-sizing: border-box;
    }

    > .ant-divider {
      display: none;
    }

    .buttons,
    .fields {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .buttons {
      justify-content: flex-end;
      margin-left: unset !important;

      @include vt-pre-lg {
        margin-top: 16px;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 16px;
      }

      > span {
        cursor: pointer;
        width: max-content;
        color: $danger;
        min-width: 60px;
      }

      > .vt-button {
        min-width: unset;
        width: 100%;
        margin: 0;
        margin-right: 16px;

        &:first-child {
          margin-right: 0;

          button {
            background: linear-gradient(53.23deg, #3364E4 27.34%, #333DC2 78.62%);

            &.disable {
              background: linear-gradient(53.23deg, #3364E4 27.34%, #333DC2 78.62%) !important;
            }
          }
        }

        &:last-child {
          button {
            &.disable {
              background: linear-gradient(91.95deg, #fc2a2a -14.4%, #ff4646 91.65%) !important;
            }
          }
        }

        @include vt-pre-lg {
          margin: 0 auto;
          font-size: 12px;
          width: 100%;
          max-width: unset;
        }
        max-width: 150px;

        button {
          font-weight: 500;
          font-size: 12px;
        }
      }
    }

    .fields {
      width: 100%;
      @include vt-pre-lg {
        flex-direction: column;
        gap: 8px;
      }

      .rmdp-container {
        .vt-input {
          width: unset;
        }
      }

      .rmdp-container {
        width: 100%;

        @include vt-pre-lg {
          width: 100% !important;
        }

        .vt-input {
          .input-field {
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding: 10px;


            input {
              height: auto !important;
            }
          }
          .adornments {
            svg {
              path {
                fill: black;
              }
            }
          }
          .input-icon {
            position: initial;
            top: initial;
            left: initial;
          }
        }
      }

      > div:not(.buttons) {
        display: flex;
        gap: 32px;
        min-width: 135px;
        width: 100%;
        color: $info;
        border-radius: 10px;
        margin-left: 16px;
        margin-right: 0;

        @include vt-pre-lg {
          margin-left: 0;
        }
      }

      .buttons {
        gap: 16px;
        display: flex;
        width: 100%;
      }

      &:first-child {
        @include vt-pre-lg {
          margin-top: 16px;
        }
      }
    }
  }
  .vt-charts {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    margin-bottom: 32px;
    @include vt-pre-lg {
      grid-template-columns: 1fr;
      gap: 16px;
      margin-top: 16px;
    }


    .vt-panel-card {
      margin-bottom: unset;
      height: auto !important;
    }

    .small-chart .highcharts-subtitle {
      display: none;
    }

    .small-chart .highcharts-legend {
      display: none;
    }


    .vt-chart {
      @include vt-size(width, 600px, 300px) ;
      @include vt-size(height, 400px, 150px) ;
    }
  }

  @include vt-pre-lg {
    .ant-row {
      display: unset !important;

      .ant-col-6,
      .ant-col-12 {
        max-width: unset !important;
      }
    }
  }

  .vt-panel-card {
    .head {
      flex-direction: row !important;
    }
  }


  .gateways-table, .trxUsdt-table, .fullReport-table {
    width: 33%;
    margin-right: 0;
    margin-left: auto;

    @include vt-pre-lg {
      width: 100%;
    }
  }

  .vt-panel-card {
    height: 128px !important;
    margin-bottom: 24px;
    display: block !important;
    cursor: pointer;
    transition: 0.7s;

    &.active {
      height: 180px !important;
    }



    .head {
      align-items: center;
      margin-bottom: 0px;

      span {
        font-size: 32px;
        color: $info;
      }

      h3 {
        margin-bottom: 0px;
      }
    }

    .card-body {
      font-weight: normal;
      margin: 24px auto;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .divider {
        @include vt-pre-lg {
          display: none;
        }
      }

      .balances {
        span {
          font-weight: 700;
        }
      }



      .prices,
      .balances,
      .users-count {
        display: flex;
        column-gap: 8px;

        @include vt-pre-lg {
          flex-direction: column;
        }
      }
    }
  }
}
