@import "src/styles/references/index";

.vt-dropdown {
  position: relative;
  width: 95%;
  max-width: max-content;
  min-width: 169px;
  height: 50px;
  border: 1px solid $table-border;
  border-radius: 5px;
  background: $white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;

  .label {
    position: absolute;
    bottom: 55px;
    font-size: 12px;
    width: max-content;
    right: 0px;
  }
  .dropdown-selector {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 90%;

    a {
      color: $primary !important;
      display: flex;
      align-items: center;

      svg {
        margin-left: 6px;
      }
    }
    .anticon {
      margin-right: 12px;
      cursor: pointer;
      svg {
        fill: #3f62fe;
      }
    }
  }
  .dropdown-items {
    position: absolute;
    width: 100%;
    max-width: 169px;
    height: max-content;
    background: $white;
    top: 102%;
    left: 0%;
    z-index: 999;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid $table-border;
    border-top: 0;
    display: flex;
    align-items: center;
    justify-content: right;
    ul {
      width: 100%;
      height: 150px;
      list-style: none;
      padding-right: 0px !important;
      margin: 0px;
      overflow-y: scroll;

      li {
        padding: 0 8px;
        width: 100%;
        height: 38px;
        color: #3f62fe;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        cursor: pointer;
        svg {
          margin-left: 6px;
        }

        > span:last-child {
          display: flex;
          align-items: center;
        }
      }
      .ant-divider {
        margin-bottom: 0;
        margin-top: 0;
      }

      li:hover {
        background: #f1f8ff;
      }
    }
  }
}
