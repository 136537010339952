@import "src/styles/references/index.scss";

.vt-dashboard-vip {
  padding: 30px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  column-gap: 50px;
  background: $white;
  border-radius: 20px;
  margin-bottom: 100px;

  [data-column-id="registeredOn"] {
    .column-date {
      span {
        display: inline-block;
        width: 100%;
      }
      span:last-child {
        font-size: 10px;
        color: $medium-gray;
      }
    }
  }

  > .card-body {
    margin: 0;
    width: 100%;
  }

  .vip-table {
    margin: 0;
  }
}
