@import "src/styles/references/index.scss";

.vt-wallet-page {
  .vt-panel-card {
    min-height: 141px;
    height: max-content;
    justify-content: space-between;

    margin-bottom: 0px !important;
    @include vt-pre-lg {
      margin-top: 0;
    }

    .head {
      margin-bottom: 0px;
    }
    .card-body {
      width: 100%;
      margin: 0px;
    }
    .main-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .right-side {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .irt-balance {
          font-weight: 900;
          font-size: 24px;
        }
        .usdt-balance {
          font-family: monospace;
          font-weight: 300;
          margin-right: 4px;
        }
      }
      .left-side {
        @include vt-pre-lg {
          display: none;
        }
        .vt-button {
          button {
            height: 38px;
          }
        }
      }
    }

    .chart-row {
      .recharts-wrapper {
        width: 100%;
        .recharts-legend-wrapper {
          right: 486px !important;
          height: max-content;
          width: 100% !important;
          ul.recharts-default-legend {
            display: flex;
            align-items: center;
            text-align: right !important;
            flex-wrap: wrap;
            row-gap: 24px;
            li {
              align-items: baseline;
              flex: 40%;
              span {
                width: max-content !important;
                margin-right: 6px;
              }
            }
          }
        }
      }
    }
  }

  .assets-row {
    margin-bottom: 80px;
    .head {
      margin-top: 32px;
      margin-bottom: 24px;
      display: flex;
      justify-content: space-between;
      width: 100%;

      @include vt-pre-lg {
        flex-direction: column;
        margin: 32px 0;
      }

      .mobile-show {
        width: 100%;
        display: none;
        justify-content: space-between;
        align-items: center;

        @include vt-pre-lg {
          display: flex;
          margin-bottom: 16px;
        }
      }
      .vt-input {
        background: $white;
        border-radius: 10px;
        width: 330px;

        @include vt-pre-lg {
          width: 100%;
        }

        .input-field {
          display: flex;
          align-items: center;
          padding: 0px 16px;

          .anticon {
            svg {
              path {
                color: $info;
              }
            }
          }
        }
      }

      > .vt-dropdown {
        @include vt-pre-lg {
          display: none;
        }
      }
    }
    .mine-assets-list {
      width: 100%;
      ul.desktop-show {
        list-style: none;
        padding: 0px;

        @include vt-pre-lg {
          display: none;
        }

        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0px 24px;
          border-radius: 10px;
          box-shadow: 0px 100.058px 80.0461px rgba(93, 128, 248, 0.05),
            0px 64.8521px 46.8788px rgba(93, 128, 248, 0.037963), 0px 38.5407px 25.4961px rgba(93, 128, 248, 0.0303704),
            0px 20.0115px 13.0075px rgba(93, 128, 248, 0.025), 0px 8.15284px 6.52227px rgba(93, 128, 248, 0.0196296),
            0px 1.85292px 3.14996px rgba(93, 128, 248, 0.012037);
          background: $white;
          height: 79px;
          margin-bottom: 16px;

          &:hover {
            background: #eff2ff;
          }
          span.coin-info {
            width: 150px;
            span {
              font-size: 14px;
              margin-right: 6px;
            }
            span:nth-child(3) {
              font-family: monospace;
              font-size: 10px;
              opacity: 0.6;
            }
            img {
              width: 26px;
            }
          }
          span.actions {
            display: flex;
            justify-content: center;

            a.detail {
              margin-left: 16px;
            }
            .vt-button {
              min-width: 100px !important;
              max-width: unset;
              button {
                min-width: 100px !important;
                max-width: unset;
                padding: 4px 24px !important;
                height: 32px;
              }
            }
          }

          b {
            font-weight: 500;
          }
        }
      }

      ul.mobile-show {
        display: none;
        background: unset !important;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        width: 100%;

        @include vt-pre-lg {
          display: flex;
          padding: 0;
        }

        > li {
          margin-bottom: 24px;
          background-color: $white;
          width: 100%;
          padding: 16px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          border: 1px solid $table-border;
          border-radius: 20px;
          max-width: 425px;

          > div {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;

            .right,
            .left {
              display: flex;
              width: max-content;
            }
          }

          > div:first-child {
            .right {
              justify-content: flex-start;
              align-items: center;

              img,
              p,
              span {
                margin-left: 8px;
              }

              img {
                width: 25px;
                height: 25px;
              }

              p {
                font-size: 12px;
                font-weight: 500;
              }

              span {
                color: $gray-text;
                font-family: "Montserrat";
                font-size: 12px;
              }
            }

            .left {
              justify-content: flex-start;
              align-items: center;

              p {
                font-family: "Montserrat" !important;
                font-size: 12px;
              }
            }
          }

          > div:nth-child(2),
          > div:nth-child(3) {
            .left {
              font-weight: 500;
            }
          }

          .actions {
            display: flex;
            justify-content: space-between;

            a {
              max-width: unset;
              margin: 0;
              width: 45%;
              button {
                height: 32px;
              }

              &:first-child {
                margin-left: 16px;
                button {
                  border: 1px solid $success;
                  color: $success;
                  background-color: $success-badge;
                }
              }
              &:last-child {
                button {
                  border: 1px solid $danger;
                  color: $danger;
                  background-color: $danger-badge;
                }
              }
            }
          }
        }

        @include vt-pre-lg {
          display: flex;
        }
      }
    }
  }
}
